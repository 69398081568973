import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const checkUserEmail = async (campaignId: string, userEmail: string) => {
  try {
    const response = await axios.get(
      `${REACT_APP_API_URL}/game/quiz/check-user/${campaignId}?email=${userEmail}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};
