import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import { Check } from "react-feather";

interface FontFamilySelectProps {
  value: string;
  onChange: (value: string) => void;
}

const FontFamilySelect: React.FC<FontFamilySelectProps> = ({
  value,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownUp, setIsDropdownUp] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const fontFamilyOptions = [
    { label: "Arial" },
    { label: "Helvetica" },
    { label: "Times New Roman" },
    { label: "Courier New" },
    { label: "Verdana" },
    { label: "Georgia" },
    { label: "Palatino" },
    { label: "Garamond" },
    { label: "Bookman" },
    { label: "Comic Sans MS" },
    { label: "Trebuchet MS" },
    { label: "Arial Black" },
    { label: "Impact" },
    { label: "Tahoma" },
    { label: "Century Gothic" },
    { label: "Consolas" },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const checkDropdownPosition = () => {
    if (dropdownRef.current && buttonRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;

      if (
        spaceBelow < dropdownRect.height &&
        spaceAbove > dropdownRect.height
      ) {
        setIsDropdownUp(true);
      } else {
        setIsDropdownUp(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", checkDropdownPosition);
    checkDropdownPosition();
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", checkDropdownPosition);
    };
  }, [isOpen]);

  const selectedOption = fontFamilyOptions.find(
    (option) => option.label === value,
  );

  return (
    <div className="relative w-[60%]" ref={dropdownRef}>
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className="flex w-full items-center justify-between rounded-md border-2 border-transparent bg-[#f5f4f7] px-4 py-2 text-left text-base shadow-sm outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <span className="flex items-center">
          {selectedOption ? selectedOption.label : "Select Font Family"}
        </span>
        <FaChevronDown
          className={`h-3 transition-transform ${isOpen ? "rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <div
          className={`absolute z-10 mt-2 max-h-32 w-full overflow-y-auto rounded-md border border-gray-300 bg-white shadow-lg ${
            isDropdownUp ? "bottom-full mb-2" : ""
          }`}
        >
          {fontFamilyOptions.map((option) => (
            <div
              key={option.label}
              onClick={() => handleOptionClick(option.label)}
              className="flex cursor-pointer items-center justify-between px-4 py-2 hover:bg-gray-100"
            >
              <span className="flex items-center">{option.label}</span>
              {option.label === value && (
                <Check className="h-4 text-blue-800" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FontFamilySelect;
