import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const linkUserToGame = async (quizId: string, token: string) => {
  try {
    const response = await axios.patch(
      `${REACT_APP_API_URL}/game/quiz/link-user/${quizId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error updating user link:", error);
    throw error;
  }
};
