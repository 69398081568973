import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useNavigate } from "react-router-dom";
import BuiltWithWorkplay from "../components/BuiltWithWorkplay";
import LogoComponent from "../components/LogoComponent";
import QuestionComponent from "../components/QuestionComponent";
import { Clock } from "react-feather";
import TimerEndPopup from "../components/TimerEndPopup";
import { useLogoSize } from "../hooks/useLogoSize";
import { useBackgroundStyle } from "../hooks/useBackgroundStyles";
import { useTextStyles } from "../hooks/useTextStyles";

type OptionType = {
  id: number;
  text: string;
};

const PreviewGamePage: React.FC = () => {
  const navigate = useNavigate();
  const quizTimer = useSelector(
    (state: RootState) => state.specification.quizTimer,
  );
  const specification = useSelector((state: RootState) => state.specification);
  const design = useSelector((state: RootState) => state.design);
  const [showTimerEndPopup, setShowTimerEndPopup] = useState(false);
  const [isLastQuestionAnswered, setIsLastQuestionAnswered] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const [selectedOptionId, setSelectedOptionId] = useState<number | null>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    const storedIndex = sessionStorage.getItem("currentQuestionIndex");
    return storedIndex ? JSON.parse(storedIndex) : 0;
  });
  const [score, setScore] = useState(() => {
    const storedScore = sessionStorage.getItem("score");
    return storedScore ? JSON.parse(storedScore) : 0;
  });
  const [remainingTime, setRemainingTime] = useState(() => {
    const storedTime = sessionStorage.getItem("remainingTime");
    return storedTime ? JSON.parse(storedTime) : quizTimer;
  });

  const logoSize = useLogoSize(specification.logo.previewUrl);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { backgroundStyle } = useBackgroundStyle();
  const textStyles = useTextStyles();

  useEffect(() => {
    setRemainingTime(quizTimer);
  }, [quizTimer]);

  useEffect(() => {
    const storedCurrentQuestionIndex = sessionStorage.getItem(
      "currentQuestionIndex",
    );
    const storedScore = sessionStorage.getItem("score");
    const storedRemainingTime = sessionStorage.getItem("remainingTime");

    if (storedCurrentQuestionIndex !== null) {
      setCurrentQuestionIndex(JSON.parse(storedCurrentQuestionIndex));
    }
    if (storedScore !== null) {
      setScore(JSON.parse(storedScore));
    }

    let initialRemainingTime = quizTimer;
    if (storedRemainingTime !== null) {
      initialRemainingTime = JSON.parse(storedRemainingTime);
      setRemainingTime(initialRemainingTime);
    } else {
      setRemainingTime(quizTimer);
    }
  }, [quizTimer]);

  useEffect(() => {
    const storedRemainingTime = sessionStorage.getItem("remainingTime");
    let currentRemainingTime = storedRemainingTime
      ? JSON.parse(storedRemainingTime)
      : quizTimer;

    setRemainingTime(currentRemainingTime);

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      setRemainingTime((prevTime: number) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current!);
          setShowTimerEndPopup(true);
          sessionStorage.setItem("remainingTime", JSON.stringify(0));
          return 0;
        } else {
          const newTime = prevTime - 1;
          sessionStorage.setItem("remainingTime", JSON.stringify(newTime));
          return newTime;
        }
      });
    }, 1000);

    return () => clearInterval(timerRef.current!);
  }, [quizTimer, currentQuestionIndex]);

  useEffect(() => {
    if (currentQuestionIndex < specification.questions.length) {
      const currentQuestion = specification.questions[currentQuestionIndex];
      const correctOption = currentQuestion?.options.find(
        (option) => option.text === currentQuestion.correctAnswer,
      );
      setSelectedOptionId(correctOption ? correctOption.id : null);
    } else {
      console.log("No more questions available.");
    }
  }, [specification.questions, currentQuestionIndex]);

  useEffect(() => {
    sessionStorage.setItem(
      "currentQuestionIndex",
      JSON.stringify(currentQuestionIndex),
    );
    sessionStorage.setItem("score", JSON.stringify(score));
    sessionStorage.setItem("remainingTime", JSON.stringify(remainingTime));
  }, [currentQuestionIndex, score, remainingTime]);

  const navigateToResultPage = () => {
    navigate("/preview-result", { replace: true });
  };

  const handleOptionClick = (option: OptionType) => {
    setShowResult(true);
    setSelectedOptionId(option.id);

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    const timeTakenForQuestion = quizTimer - remainingTime;
    const totalTimeTaken = sessionStorage.getItem("totalTimeTaken")
      ? JSON.parse(sessionStorage.getItem("totalTimeTaken")!)
      : 0;
    const newTotalTimeTaken = totalTimeTaken + timeTakenForQuestion;
    sessionStorage.setItem("totalTimeTaken", JSON.stringify(newTotalTimeTaken));

    if (
      option.text ===
      specification.questions[currentQuestionIndex]?.correctAnswer
    ) {
      setScore((prevScore: number) => {
        const newScore = prevScore + 1;
        sessionStorage.setItem("score", JSON.stringify(newScore));
        return newScore;
      });
    }

    setTimeout(() => {
      if (currentQuestionIndex + 1 < specification.questions.length) {
        handleNextQuestion();
      } else {
        setIsLastQuestionAnswered(true);
        setTimeout(() => {
          navigateToResultPage();
        }, 500);
      }
    }, 1200);
  };

  const handleNextQuestion = () => {
    setShowResult(false);
    setSelectedOptionId(null);

    if (currentQuestionIndex + 1 < specification.questions.length) {
      setCurrentQuestionIndex((prevIndex: number) => {
        const newIndex = prevIndex + 1;
        sessionStorage.setItem(
          "currentQuestionIndex",
          JSON.stringify(newIndex),
        );
        return newIndex;
      });
    } else {
      navigateToResultPage();
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    let currentRemainingTime = quizTimer;
    setRemainingTime(currentRemainingTime);
    sessionStorage.setItem(
      "remainingTime",
      JSON.stringify(currentRemainingTime),
    );

    timerRef.current = setInterval(() => {
      currentRemainingTime -= 1;
      if (currentRemainingTime <= 0) {
        setShowTimerEndPopup(true);
        clearInterval(timerRef.current!);
        currentRemainingTime = 0;
      }
      setRemainingTime(currentRemainingTime);
      sessionStorage.setItem(
        "remainingTime",
        JSON.stringify(currentRemainingTime),
      );
    }, 1000);
  };

  const handleExitPreview = () => {
    navigate(-1);
    const itemsToRemove = [
      "previewName",
      "previewEmail",
      "currentQuestionIndex",
      "score",
      "remainingTime",
      "totalTimeTaken",
    ];
    itemsToRemove.forEach((item) => sessionStorage.removeItem(item));
  };

  const handleTimeUp = () => {
    setShowTimerEndPopup(false);
    navigate("/preview", {
      replace: true,
    });
    sessionStorage.removeItem("previewName");
    sessionStorage.removeItem("previewEmail");
    sessionStorage.removeItem("currentQuestionIndex");
    sessionStorage.removeItem("score");
    sessionStorage.removeItem("remainingTime");
    sessionStorage.removeItem("totalTimeTaken");
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white">
      <div
        className="relative flex h-full w-full flex-col items-center justify-center p-4"
        style={backgroundStyle}
      >
        <div
          className="-mt-10 flex min-w-[300px] items-center justify-center rounded-xl px-6 py-10 lg:min-w-[400px] lg:p-10"
          style={{
            backgroundColor:
              design.backgroundType === "uploadImage"
                ? `rgba(${parseInt(design.uploadImage.secondaryBackgroundColor.slice(1, 3), 16)}, ${parseInt(design.uploadImage.secondaryBackgroundColor.slice(3, 5), 16)}, ${parseInt(design.uploadImage.secondaryBackgroundColor.slice(5, 7), 16)}, 0.7)`
                : `rgba(${parseInt(design.colorPalette.selectedBackgroundColor.slice(1, 3), 16)}, ${parseInt(design.colorPalette.selectedBackgroundColor.slice(3, 5), 16)}, ${parseInt(design.colorPalette.selectedBackgroundColor.slice(5, 7), 16)}, 0.7)`,
            fontFamily: textStyles.fontFamily,
            outlineColor:
              design.backgroundType === "uploadImage"
                ? design.uploadImage.secondaryBackgroundColor
                : design.colorPalette.selectedBackgroundColor,
          }}
        >
          <div className="w-full">
            <LogoComponent specification={specification} logoSize={logoSize} />
            <div className="flex w-full justify-center space-x-4">
              <div
                className="flex items-center space-x-2 rounded-full px-4 py-2"
                style={
                  design.backgroundType === "uploadImage"
                    ? {
                        backgroundColor: `rgba(${parseInt(design.uploadImage.textColor.slice(1, 3), 16)}, ${parseInt(design.uploadImage.textColor.slice(3, 5), 16)}, ${parseInt(design.uploadImage.textColor.slice(5, 7), 16)}, 0.8)`,
                        color: design.uploadImage.secondaryBackgroundColor,
                        fontSize: textStyles.scoreSize,
                        fontFamily: textStyles.fontFamily,
                      }
                    : {
                        backgroundColor: "black",
                        color: "white",
                        fontSize: textStyles.scoreSize,
                        fontFamily: textStyles.fontFamily,
                      }
                }
              >
                <span>Score: {score}</span>
              </div>
              <div
                className="flex items-center space-x-2 rounded-full px-4 py-2"
                style={
                  design.backgroundType === "uploadImage"
                    ? {
                        backgroundColor: `rgba(${parseInt(design.uploadImage.textColor.slice(1, 3), 16)}, ${parseInt(design.uploadImage.textColor.slice(3, 5), 16)}, ${parseInt(design.uploadImage.textColor.slice(5, 7), 16)}, 0.8)`,
                        color: design.uploadImage.secondaryBackgroundColor,
                        fontSize: textStyles.scoreSize,
                        fontFamily: textStyles.fontFamily,
                      }
                    : {
                        backgroundColor: "black",
                        color: "white",
                        fontSize: textStyles.scoreSize,
                        fontFamily: textStyles.fontFamily,
                      }
                }
              >
                <Clock className="h-4 w-4" />
                <span>
                  00:{remainingTime < 10 ? `0${remainingTime}` : remainingTime}
                </span>
              </div>
            </div>
            <div className="my-1 flex w-full justify-center">
              <div className="my-4 flex w-full justify-center">
                <div className="flex w-full">
                  {specification.questions.map((_, index) => (
                    <div
                      key={index}
                      className={`mx-1 h-[3px] flex-1 rounded-full ${
                        index < currentQuestionIndex ||
                        (index === currentQuestionIndex &&
                          isLastQuestionAnswered)
                          ? "bg-white"
                          : "bg-white opacity-40"
                      }`}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
            <QuestionComponent
              currentQuestionIndex={currentQuestionIndex}
              specification={specification}
              design={design}
              textStyles={textStyles}
              showResult={showResult}
              selectedOptionId={selectedOptionId}
              handleOptionClick={handleOptionClick}
            />
          </div>
        </div>
        <BuiltWithWorkplay />

        <button
          className="absolute bottom-14 rounded-full bg-black px-4 py-2 text-center text-white hover:scale-105 hover:opacity-80"
          onClick={handleExitPreview}
        >
          Exit Preview
        </button>

        {showTimerEndPopup && (
          <TimerEndPopup
            textStyles={textStyles}
            design={design}
            handleTimeUp={handleTimeUp}
          />
        )}
      </div>
    </div>
  );
};

export default PreviewGamePage;
