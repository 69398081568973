const useDynamicStyles = (quizDetails: any) => {
  const defaultTextSize = 24;

  const backgroundStyle = quizDetails?.background?.image
    ? {
        color: quizDetails.background.textColor,
        backgroundColor:
          quizDetails.background.primaryBColor ||
          quizDetails.background.secondaryBColor,
        backgroundImage: quizDetails.background.previewUrl
          ? `url(${quizDetails.background.previewUrl})`
          : "none",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }
    : {
        color: quizDetails?.background?.textColor,
        backgroundColor: quizDetails?.background?.backgroundColor,
      };

  const buttonBackgroundColor = quizDetails?.background?.primaryBColor;

  const textStyles = {
    fontFamily: quizDetails?.fontFamily || "Arial, sans-serif",
    titleSize: `${quizDetails?.textSize || defaultTextSize}px`,
    descriptionSize: `${Math.round((quizDetails?.textSize || defaultTextSize) * 0.82)}px`,
    inputSize: `${Math.round((quizDetails?.textSize || defaultTextSize) * 0.73)}px`,
    buttonSize: `${Math.round((quizDetails?.textSize || defaultTextSize) * 0.68)}px`,
    scoreSize: `${Math.round((quizDetails?.textSize || defaultTextSize) * 0.64)}px`,
    timerSize: `${Math.round((quizDetails?.textSize || defaultTextSize) * 0.64)}px`,
    questionSize: `${Math.round((quizDetails?.textSize || defaultTextSize) * 0.77)}px`,
    optionSize: `${Math.round((quizDetails?.textSize || defaultTextSize) * 0.68)}px`,
  };

  return { backgroundStyle, buttonBackgroundColor, textStyles };
};

export default useDynamicStyles;
