import React, { useEffect, useRef, useState } from "react";
import {
  FaHandPointer,
  FaPalette,
  FaPencilAlt,
  FaQuestion,
  FaUser,
} from "react-icons/fa";

interface MobileSidebarProps {
  setActiveContent: (content: string | null) => void;
  activeContent: string | null;
}

const MobileSidebar: React.FC<MobileSidebarProps> = ({
  setActiveContent,
  activeContent,
}) => {
  const [showHelpDropdown, setShowHelpDropdown] = useState(false);
  const helpRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (helpRef.current && !helpRef.current.contains(event.target as Node)) {
        setShowHelpDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [helpRef]);

  const handleContactSupport = () => {
    window.open(`${process.env.REACT_APP_WORKPLAY_URL}/contact`, "_blank");
    setShowHelpDropdown(false);
  };

  return (
    <div className="relative">
      <div className="fixed bottom-0 left-0 right-0 flex items-center justify-around border-t border-gray-300 bg-white py-3 shadow-lg">
        <button
          onClick={() => setActiveContent("specification")}
          className={`flex flex-col items-center py-1 ${activeContent === "specification" ? "text-blue-500" : "text-black"}`}
        >
          <FaPencilAlt size={20} />
          <span className="text-sm">Specification</span>
        </button>
        <button
          onClick={() => setActiveContent("design")}
          className={`flex flex-col items-center py-1 ${activeContent === "design" ? "text-blue-500" : "text-black"}`}
        >
          <FaPalette size={20} />
          <span className="text-sm">Design</span>
        </button>
        <button
          onClick={() => setActiveContent("interactivity")}
          className={`flex flex-col items-center py-1 ${activeContent === "interactivity" ? "text-blue-500" : "text-black"}`}
        >
          <FaHandPointer size={20} />
          <span className="text-sm">Interactivity</span>
        </button>
      </div>

      <div className="fixed bottom-24 right-4 z-50" ref={helpRef}>
        <button
          onClick={() => setShowHelpDropdown((prev) => !prev)}
          className="flex h-10 w-10 items-center justify-center rounded-full bg-black text-white shadow-lg"
        >
          <FaQuestion size={14} />
        </button>

        {showHelpDropdown && (
          <div className="absolute bottom-12 right-0 mb-2 w-48 rounded-xl border border-gray-300 bg-white p-4 shadow-lg drop-shadow">
            <div className="absolute right-4 top-full h-0 w-0 border-l-8 border-r-8 border-t-8 border-l-transparent border-r-transparent border-t-white"></div>
            <p
              className="flex cursor-pointer items-center space-x-2 rounded-lg text-[15px] hover:bg-gray-100"
              onClick={handleContactSupport}
            >
              <FaUser size={14} />
              <span>Contact support</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileSidebar;
