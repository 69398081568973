import { RootState } from "../store";

export const createQuizData = (
  specification: RootState["specification"],
  design: RootState["design"],
  interactivity: RootState["interactivity"],
) => {
  const instructionsArray = specification.instructions.map((inst) => inst.text);

  const quizData: any = {
    leaderboard: interactivity.displayLeaderboard,
    leaderboardLimit: interactivity.displayLeaderboard
      ? interactivity.numberOfWinners
      : 0,
    questions: specification.questions.map((question) => ({
      question: question.text,
      options: question.options.map((option) => option.text),
      correctOptionValue: question.correctAnswer,
    })),
    background: {
      textColor:
        design.backgroundType === "colorPalette"
          ? design.colorPalette.textColor
          : design.uploadImage.textColor,
      ...(design.backgroundType === "colorPalette" && {
        backgroundColor: design.colorPalette.selectedBackgroundColor,
      }),
      ...(design.backgroundType === "uploadImage" && {
        primaryBColor: design.uploadImage.primaryBackgroundColor,
        secondaryBColor: design.uploadImage.secondaryBackgroundColor,
        image:
          design.uploadImage.backgroundFilename || "default-background.webp",
      }),
    },
    title: specification.title,
    description: specification.description,
    textSize: design.textSizes.title,
    fontFamily: design.fontFamily,
    timerInSeconds: specification.quizTimer,
    displayInstructions: specification.displayInstructions,
  };

  if (specification.displayInstructions) {
    quizData.instructions = instructionsArray;
  }

  // Ensure the existing logo information is preserved
  if (specification.logo.file && specification.logo.previewUrl) {
    quizData.logo = {
      image: specification.logoFilename,
    };
    if (specification.websiteUrl) {
      quizData.logo.websiteUrl = specification.websiteUrl;
    }
  } else if (specification.logoFilename) {
    quizData.logo = {
      image: specification.logoFilename,
    };
    if (specification.websiteUrl) {
      quizData.logo.websiteUrl = specification.websiteUrl;
    }
  } else {
    quizData.logo = {};
  }

  return quizData;
};
