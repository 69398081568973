import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Minus, Plus, Trash } from "react-feather";
import { FaChevronDown } from "react-icons/fa";
import {
  setUploadImage,
  setTextSize,
  setFontFamily,
  setBackgroundFilename,
} from "../store/slices/designSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import FontFamilySelect from "./FontFamilySelect";
import { uploadBackgroundImage } from "../api/uploadBackgroundImageAPI";

interface BackgroundImageUploadProps {
  textColor: string;
  primaryBackgroundColor: string;
  secondaryBackgroundColor: string;
  onTextColorChange: (color: string) => void;
  onPrimaryBackgroundColorChange: (color: string) => void;
  onSecondaryBackgroundColorChange: (color: string) => void;
  onImageUpload: (file: File | null) => void;
}

const BackgroundImageUpload: React.FC<BackgroundImageUploadProps> = ({
  textColor,
  primaryBackgroundColor,
  secondaryBackgroundColor,
  onTextColorChange,
  onPrimaryBackgroundColorChange,
  onSecondaryBackgroundColorChange,
  onImageUpload,
}) => {
  const { previewUrl, fontFamily, textSizes } = useSelector(
    (state: RootState) => ({
      previewUrl: state.design.uploadImage.previewUrl,
      fontFamily: state.design.fontFamily,
      textSizes: state.design.textSizes,
    }),
  );
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
          const result = e.target?.result as string;
          dispatch(setUploadImage({ file: null, previewUrl: result }));
          onImageUpload(file);
          try {
            const response = await uploadBackgroundImage(file);
            console.log("Upload response:", response);
            if (response && response.data && response.data.fileName) {
              const filename = response.data.fileName;
              dispatch(setBackgroundFilename(filename));
            }
          } catch (error) {
            console.error("Error uploading background image:", error);
          }
        };
        reader.readAsDataURL(file);
      }
    },
    [dispatch, onImageUpload],
  );

  const handleDeleteImage = () => {
    dispatch(setUploadImage({ file: null, previewUrl: null }));
    onImageUpload(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    multiple: false,
  });

  return (
    <div>
      <div className="mb-5">
        <h3 className="text-base font-semibold">Text</h3>
        <div className="mt-2 flex items-center justify-between">
          <label className="block text-base font-medium">Text color</label>
          <div className="relative flex items-center rounded-md border border-[#dadde1] p-1">
            <div
              className="h-6 w-6 rounded-md shadow-inner"
              style={{ backgroundColor: textColor }}
            >
              <input
                type="color"
                value={textColor}
                onChange={(e) => onTextColorChange(e.target.value)}
                className="absolute inset-0 cursor-pointer opacity-0"
              />
            </div>
            <FaChevronDown className="ml-2 h-3 text-gray-400" />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-between">
          <label className="block text-base font-medium">Text Size</label>
          <div className="flex items-center rounded-md border border-[#dadde1]">
            <button
              onClick={() =>
                dispatch(
                  setTextSize({
                    title: textSizes.title + 1,
                    description: textSizes.description + 1,
                    input: textSizes.input + 1,
                    button: textSizes.button + 1,
                    score: textSizes.score + 1,
                    timer: textSizes.timer + 1,
                    question: textSizes.question + 1,
                    option: textSizes.option + 1,
                  }),
                )
              }
              disabled={textSizes.title >= 24}
              className={`${textSizes.title >= 24 ? "cursor-not-allowed rounded-l-md bg-gray-100" : ""} p-2 hover:opacity-60`}
            >
              <Plus className="h-4 w-4" />
            </button>
            <div className="h-8 border-l border-[#dadde1]"></div>
            <button
              onClick={() =>
                dispatch(
                  setTextSize({
                    title: textSizes.title - 1,
                    description: textSizes.description - 1,
                    input: textSizes.input - 1,
                    button: textSizes.button - 1,
                    score: textSizes.score - 1,
                    timer: textSizes.timer - 1,
                    question: textSizes.question - 1,
                    option: textSizes.option - 1,
                  }),
                )
              }
              disabled={textSizes.title <= 20}
              className={`${textSizes.title <= 20 ? "cursor-not-allowed rounded-r-md bg-gray-100" : ""} p-2 hover:opacity-60`}
            >
              <Minus className="h-4 w-4" />
            </button>
          </div>
        </div>
        <div className="mt-4 flex items-center justify-between">
          <label className="block text-base font-medium">Font</label>
          <FontFamilySelect
            value={fontFamily}
            onChange={(value) => dispatch(setFontFamily(value))}
          />
        </div>
      </div>
      <div className="my-5 border-b border-gray-200"></div>
      <div>
        <h3 className="pt-2 text-base font-semibold">Background</h3>
        <div className="mt-2 flex items-center justify-between">
          <label className="block text-base font-medium">Primary Color</label>
          <div className="relative flex items-center rounded-md border border-[#dadde1] p-1">
            <div
              className="h-6 w-6 rounded-md shadow-inner"
              style={{ backgroundColor: primaryBackgroundColor }}
            >
              <input
                type="color"
                value={primaryBackgroundColor}
                onChange={(e) => onPrimaryBackgroundColorChange(e.target.value)}
                className="absolute inset-0 cursor-pointer opacity-0"
              />
            </div>
            <FaChevronDown className="ml-2 h-3 text-gray-400" />
          </div>
        </div>
        <div className="my-4 flex items-center justify-between">
          <label className="block text-base font-medium">Secondary Color</label>
          <div className="relative flex items-center rounded-md border border-[#dadde1] p-1">
            <div
              className="h-6 w-6 rounded-md shadow-inner"
              style={{ backgroundColor: secondaryBackgroundColor }}
            >
              <input
                type="color"
                value={secondaryBackgroundColor}
                onChange={(e) =>
                  onSecondaryBackgroundColorChange(e.target.value)
                }
                className="absolute inset-0 cursor-pointer opacity-0"
              />
            </div>
            <FaChevronDown className="ml-2 h-3 text-gray-400" />
          </div>
        </div>
      </div>
      <div>
        <label className="block text-base font-medium">Image</label>
        <p className="text-sm text-gray-500">
          We support png, gif, jpg, and svg
        </p>
        {previewUrl ? (
          <div className="mb-8 mt-6 flex items-center justify-between space-x-4 rounded-md border border-[#dadde1] bg-[#f5f4f7] p-4 text-center shadow-sm outline-none hover:border-blue-500 hover:ring-1 focus:border-transparent focus:outline-none focus:ring-blue-500">
            <img
              src={previewUrl}
              alt="Uploaded"
              className="h-20 w-20 rounded-md border border-[#dadde1]"
            />

            <button
              className="flex items-center rounded-md text-base font-medium text-primary underline"
              {...getRootProps()}
            >
              Update image
            </button>
            <button
              className="flex items-center text-primary"
              onClick={handleDeleteImage}
            >
              <Trash className="mr-2 h-5" />
            </button>

            <input {...getInputProps()} />
          </div>
        ) : (
          <div
            {...getRootProps()}
            className="mb-8 mt-6 flex cursor-pointer items-center justify-start rounded-md border border-[#dadde1] p-6 text-center hover:border-primary"
          >
            <input {...getInputProps()} />
            <div className="flex items-center justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                preserveAspectRatio="xMidYMid meet"
                width="64"
                height="64"
                viewBox="0 0 48 48"
                className="ml-10"
              >
                <g color="inherit">
                  <g style={{ opacity: "var(--dropzone-icon-opacity, .5)" }}>
                    <circle
                      cx="27.84"
                      cy="15.36"
                      r="3.84"
                      style={{
                        transition: "all 0.3s ease 0s",
                        transform:
                          "var(--dropzone-icon-primary-transform, none)",
                        fill: "var(--dropzone-icon-primary, #161617)",
                      }}
                    ></circle>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M26.3553 27.7139L19.92 19.44L8.16 34.56L21.12 34.56L31.68 34.56L39.84 34.56L30.48 22.32L26.3553 27.7139Z"
                      style={{
                        transition: "all 0.3s ease 0s",
                        fill: "var(--dropzone-icon-secondary, rgba(0, 0, 0, 0.55))",
                      }}
                    ></path>
                  </g>
                </g>
              </svg>
              <p className="w-40 text-primary">
                Drag and drop or{" "}
                <span className="cursor-pointer text-black underline">
                  Click to add image
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BackgroundImageUpload;
