import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import MainContent from "../components/MainContent";
import Sidebar from "../components/Sidebar";
import SideContent from "../components/SideContent";
import { useLocation, useNavigate } from "react-router-dom";
import MobileSidebar from "../components/MobileSidebar";
import MobileSideContent from "../components/MobileSideContent";
import LogoLoader from "../components/LogoLoader";
import Favicon from "../assets/WorkPlay_FaviCon.svg";
import { useDispatch } from "react-redux";
import { resetQuiz } from "../store/slices/specificationSlice";
import { resetDesign } from "../store/slices/designSlice";
import { resetInteractivity } from "../store/slices/InteractivitySlice";
import { resetGame } from "../store/slices/gameSlice";

const CustomizeQuizPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeContent, setActiveContent] = useState<string | null>(
    "specification",
  );
  const [mobileActiveContent, setMobileActiveContent] = useState<string | null>(
    null,
  );
  const [loading, setLoading] = useState(true);

  const handleReset = useCallback(() => {
    dispatch(resetQuiz());
    dispatch(resetDesign());
    dispatch(resetInteractivity());
    dispatch(resetGame());
  }, [dispatch]);

  useEffect(() => {
    const favicon = document.querySelector(
      "link[rel='icon']",
    ) as HTMLLinkElement;
    const originalFavicon = favicon.href;
    const originalTitle = document.title;

    document.title = "WorkPlay Studio Pvt Ltd - Quiz customization";
    favicon.href = `${Favicon}`;

    return () => {
      document.title = originalTitle;
      favicon.href = originalFavicon;
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const ccid = urlParams.get("ccid");
    const storedCcid = sessionStorage.getItem("ccid");
    const source = urlParams.get("source");

    if (source === "game") {
      handleReset();
      urlParams.delete("source");
      navigate(`${location.pathname}?${urlParams.toString()}`, {
        replace: true,
      });
    } else if (
      ccid &&
      ccid !== storedCcid &&
      urlParams.toString() === `ccid=${ccid}`
    ) {
      handleReset();
      sessionStorage.setItem("ccid", ccid);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [handleReset, location, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const removeLeaderboardItems = () => {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith("leaderboard_")) {
          localStorage.removeItem(key);
        }
      }
    };

    if (!location.pathname.includes("/preview")) {
      sessionStorage.removeItem("previewName");
      sessionStorage.removeItem("previewEmail");
      sessionStorage.removeItem("currentQuestionIndex");
      sessionStorage.removeItem("score");
      sessionStorage.removeItem("remainingTime");
      sessionStorage.removeItem("totalTimeTaken");
    }
    if (
      !location.pathname.includes("/play") &&
      !location.pathname.includes("/game") &&
      !location.pathname.includes("/result")
    ) {
      sessionStorage.removeItem("quizScore");
      sessionStorage.removeItem("quizRemainingTime");
      sessionStorage.removeItem("quizCurrentQuestionIndex");
      sessionStorage.removeItem("quizUserName");
      sessionStorage.removeItem("quizUserEmail");
      sessionStorage.removeItem("quizTotalTimeTaken");
      removeLeaderboardItems();
    }
  }, [location.pathname]);

  if (loading) {
    return <LogoLoader />;
  }

  return (
    <div className="flex h-screen flex-col">
      <div className="sticky top-0 z-10">
        <Navbar />
      </div>
      <div className="hidden lg:flex lg:flex-grow lg:overflow-hidden">
        <MainContent className={activeContent ? "w-[55%]" : "w-full"} />
        {activeContent && <SideContent activeContent={activeContent} />}
        <Sidebar
          setActiveContent={setActiveContent}
          activeContent={activeContent}
        />
      </div>
      <div className="flex flex-col lg:hidden">
        <MainContent className={activeContent ? "w-full" : "w-full"} />
        {mobileActiveContent && (
          <MobileSideContent
            activeContent={mobileActiveContent}
            setActiveContent={setMobileActiveContent}
          />
        )}
        <MobileSidebar
          setActiveContent={setMobileActiveContent}
          activeContent={mobileActiveContent}
        />
      </div>
    </div>
  );
};

export default CustomizeQuizPage;
