import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const getLeaderboard = async (quizId: string) => {
  try {
    const response = await axios.get(
      `${REACT_APP_API_URL}/game/quiz/leaderboard/${quizId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching leaderboard data:", error);
    throw error;
  }
};
