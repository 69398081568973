import React, { useState, useEffect, useRef } from "react";
import { Check } from "react-feather";
import { FaChevronDown } from "react-icons/fa";

interface CustomSelectProps {
  value: string;
  options: { label: string; icon?: string }[];
  onChange: (value: string) => void;
  placeholder?: string;
  customClass?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  value,
  options,
  onChange,
  placeholder,
  customClass,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedOption = options.find((option) => option.label === value);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex w-full items-center justify-between rounded-md border-2 border-transparent bg-[#f5f4f7] px-4 py-2 text-left text-base shadow-sm outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <span className={`flex items-center ${customClass}`}>
          {selectedOption?.icon && (
            <img src={selectedOption.icon} alt="" className="mr-2 h-5 w-5" />
          )}
          {selectedOption ? selectedOption.label : placeholder}
        </span>
        <FaChevronDown
          className={`h-3 transition-transform ${isOpen ? "rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-2 w-full rounded-md border border-gray-300 bg-white shadow-lg">
          {options.map((option) => (
            <div
              key={option.label}
              onClick={() => handleOptionClick(option.label)}
              className="flex cursor-pointer items-center justify-between px-4 py-2 hover:bg-gray-100"
            >
              <span className="flex items-center">
                {option.icon && (
                  <img src={option.icon} alt="" className="mr-2 h-5 w-5" />
                )}
                {option.label}
              </span>
              {option.label === value && (
                <Check className="h-4 text-blue-800" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
