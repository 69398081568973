import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";

const useGameStyles = (quizDetails: any) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

  const backgroundStyle = useMemo(() => {
    if (quizDetails?.background?.image) {
      return {
        color: quizDetails.background.textColor,
        backgroundColor: quizDetails.background.primaryBColor,
        backgroundImage: `url(${process.env.REACT_APP_S3_URL}/quiz/background/${quizDetails.background.image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      };
    } else {
      return {
        color: quizDetails?.background?.textColor,
        backgroundColor: quizDetails?.background?.backgroundColor,
      };
    }
  }, [quizDetails]);

  const textStyles = useMemo(() => {
    const baseSize = quizDetails?.textSize || 22;
    const scaleFactor = isSmallScreen ? 0.7 : 0.85; // Adjust the scale factor for small screens

    return {
      fontFamily: quizDetails?.fontFamily,
      scoreSize: isSmallScreen ? `${baseSize * 0.6}px` : `${baseSize * 0.7}px`,
      timerSize: isSmallScreen ? `${baseSize * 0.6}px` : `${baseSize * 0.7}px`,
      descriptionSize: isSmallScreen
        ? `${baseSize * 0.76}px`
        : `${baseSize * 0.85}px`,
      titleSize: isSmallScreen ? `${baseSize * 0.92}px` : `${baseSize}px`,
      instructionSize: isSmallScreen
        ? `${baseSize * 0.71}px`
        : `${baseSize * 0.76}px`,
      questionSize: isSmallScreen
        ? `${baseSize * 0.7}px`
        : `${baseSize * 0.82}px`,
      inputSize: isSmallScreen ? `${baseSize * 0.7}px` : `${baseSize * 0.72}px`,
      optionSize: isSmallScreen
        ? `${baseSize * 0.6}px`
        : `${baseSize * 0.74}px`,
      leaderboardSize: `${baseSize * scaleFactor}px`,

      buttonSize: isSmallScreen
        ? `${baseSize * 0.7}px`
        : `${baseSize * 0.74}px`,
    };
  }, [quizDetails, isSmallScreen]);

  const buttonBackgroundColor = quizDetails?.background?.image
    ? quizDetails.background.primaryBColor
    : "bg-yellow-500";

  return { backgroundStyle, textStyles, buttonBackgroundColor };
};

export default useGameStyles;
