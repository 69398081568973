import React from "react";
import { X } from "react-feather";
import Specification from "./Specification";
import Design from "./Design";
import Interactivity from "./Interactivity";
import { useSelector } from "react-redux";
import { RootState } from "../store";

interface MobileSideContentProps {
  activeContent: string;
  setActiveContent: (content: string | null) => void;
}

const MobileSideContent: React.FC<MobileSideContentProps> = ({
  activeContent,
  setActiveContent,
}) => {
  const { backgroundType } = useSelector((state: RootState) => state.design);

  return (
    <div className="fixed inset-x-0 bottom-16 top-32 z-50 overflow-auto bg-white">
      <div className="flex h-full flex-col rounded-xl border border-secondary bg-white drop-shadow">
        <div className="flex items-center justify-between px-4 pb-4 pt-6 lg:px-8">
          <h2 className="text-lg font-medium">
            {activeContent === "specification"
              ? "Specification"
              : activeContent.charAt(0).toUpperCase() + activeContent.slice(1)}
          </h2>
          <button
            onClick={() => setActiveContent(null)}
            className="text-gray-600"
          >
            <X />
          </button>
        </div>
        <div
          className={`flex-grow ${
            activeContent === "interactivity" ||
            (activeContent === "design" && backgroundType === "colorPalette")
              ? "overflow-y-auto"
              : "overflow-y-auto"
          } px-4 text-start lg:px-8`}
        >
          {activeContent === "specification" && <Specification />}
          {activeContent === "design" && <Design />}
          {activeContent === "interactivity" && <Interactivity />}
        </div>
      </div>
    </div>
  );
};

export default MobileSideContent;
