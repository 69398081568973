import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface GameState {
  currentQuestionIndex: number;
  score: number;
  remainingTime: number;
  userName?: string;
  userEmail?: string;
  quizDetails?: any;
}

const initialState: GameState = {
  currentQuestionIndex: 0,
  score: 0,
  remainingTime: 0,
  userName: "",
  userEmail: "",
  quizDetails: null,
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    resetGame: () => initialState,
    setCurrentQuestionIndex: (state, action: PayloadAction<number>) => {
      state.currentQuestionIndex = action.payload;
    },
    setScore: (state, action: PayloadAction<number>) => {
      state.score = action.payload;
    },
    setRemainingTime: (state, action: PayloadAction<number>) => {
      state.remainingTime = action.payload;
    },
    setUserName: (state, action: PayloadAction<string | undefined>) => {
      state.userName = action.payload;
    },
    setQuizDetails: (state, action: PayloadAction<any>) => {
      state.quizDetails = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<string | undefined>) => {
      state.userEmail = action.payload;
    },
  },
});

export const {
  resetGame,
  setCurrentQuestionIndex,
  setScore,
  setRemainingTime,
  setUserName,
  setUserEmail,
  setQuizDetails,
} = gameSlice.actions;

export default gameSlice.reducer;
