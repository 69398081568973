import { useEffect, useState } from "react";

interface LogoSize {
  width: number;
  height: number;
}

interface UseLogoSizeConfig {
  minHeightPercentage?: number;
  maxHeightPercentage?: number;
  maxWidthPercentage?: number;
}

export const useLogoSize = (
  logoUrl: string | null,
  config: UseLogoSizeConfig = {},
): LogoSize => {
  const [logoSize, setLogoSize] = useState<LogoSize>({ width: 0, height: 0 });

  useEffect(() => {
    if (logoUrl) {
      const img = new Image();
      img.src = logoUrl;
      img.onload = () => {
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;
        const aspectRatio = img.width / img.height;

        const {
          minHeightPercentage = 0.03,
          maxHeightPercentage = 0.12,
          maxWidthPercentage = 0.3,
        } = config;

        let logoHeight;
        let logoWidth;

        if (aspectRatio > 1.5) {
          logoHeight = screenHeight * minHeightPercentage;
        } else {
          logoHeight = screenHeight * maxHeightPercentage;
        }

        logoWidth = logoHeight * aspectRatio;

        const maxWidth = screenWidth * maxWidthPercentage;
        if (logoWidth > maxWidth) {
          logoWidth = maxWidth;
          logoHeight = maxWidth / aspectRatio;
        }

        setLogoSize({ width: logoWidth, height: logoHeight });
      };
    }
  }, [logoUrl, config]);

  return logoSize;
};
