import React, { useState } from "react";
import TooltipComponent from "./TooltipComponent";
import { useDispatch, useSelector } from "react-redux";
import { resetQuiz } from "../store/slices/specificationSlice";
import { resetDesign } from "../store/slices/designSlice";
import { resetInteractivity } from "../store/slices/InteractivitySlice";
import { useNavigate } from "react-router-dom";
import { generateQuizLink } from "../api/customQuizAPI";
import { RootState } from "../store";
import toast from "react-hot-toast";
import { ArrowLeft, Eye, X } from "react-feather";
import useFetchAndSetQuizDetails from "../hooks/useFetchAndSetQuizDetails";
import { createQuizData } from "../utils/quizData";
import { updateQuizLink } from "../api/updateQuizAPI";
import { linkUserToGame } from "../api/linkUserToGameAPI";
import { resetGame } from "../store/slices/gameSlice";

const { REACT_APP_S3_URL, REACT_APP_WORKPLAY_URL } = process.env;

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const [showResetDropdown, setShowResetDropdown] = useState(false);
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);

  const specification = useSelector((state: RootState) => state.specification);
  const design = useSelector((state: RootState) => state.design);
  const interactivity = useSelector((state: RootState) => state.interactivity);
  const campaignId = sessionStorage.getItem("campaignId");

  const urlParams = new URLSearchParams(window.location.search);
  const gameId = urlParams.get("gameId");
  const ccid = urlParams.get("ccid");
  const token = urlParams.get("xSudTzMYmd");

  // Use the custom hook unconditionally
  useFetchAndSetQuizDetails(gameId, ccid, token);

  const handleResetConfirm = () => {
    dispatch(resetQuiz());
    dispatch(resetDesign());
    dispatch(resetInteractivity());
    dispatch(resetGame());
    setShowResetDropdown(false);
  };

  const handleGenerateLink = async () => {
    for (let i = 0; i < specification.questions.length; i++) {
      const question = specification.questions[i];

      if (!question.correctAnswer) {
        toast.error(`Select the correct answer for question ${i + 1}`, {
          style: { fontFamily: design.fontFamily },
        });
        return; // Prevent further execution if any question is missing a correct answer
      }

      const correctOptionExists = question.options.some(
        (option) => option.text === question.correctAnswer,
      );

      if (!correctOptionExists) {
        toast.error(`Select the correct answer for question ${i + 1}`, {
          style: { fontFamily: design.fontFamily },
        });
        return; // Prevent further execution if the correct answer does not exist in the options
      }

      for (let j = 0; j < question.options.length; j++) {
        if (!question.options[j].text) {
          toast.error(`Option for question ${i + 1} is not selected`, {
            style: { fontFamily: design.fontFamily },
          });
          return; // Prevent further execution if any option is missing for the question
        }
      }
    }

    const quizData = createQuizData(specification, design, interactivity);

    try {
      const response = await generateQuizLink(quizData);
      sessionStorage.setItem("campaignId", response.data._id);
      setShowPopup(true);
      console.log("Generate link response:", response);
    } catch (error) {
      console.error("Error generating quiz link:", error);
    }
  };

  const handleGetLink = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const ccid = urlParams.get("ccid");

    setTimeout(() => {
      if (ccid) {
        sessionStorage.clear();
        window.location.href = `${REACT_APP_WORKPLAY_URL}/dashboard/campaigns/create?ccid=${ccid}&gameId=${campaignId}`;
        handleResetConfirm();
      } else {
        sessionStorage.clear();
        window.location.href = `${REACT_APP_WORKPLAY_URL}/login?from=quiz&gameId=${campaignId}`;
        handleResetConfirm();
      }
    }, 1000);
  };

  const handleUpdateLink = async () => {
    if (gameId && token && ccid) {
      const quizData = createQuizData(specification, design, interactivity);

      if (design.backgroundType === "uploadImage") {
        quizData.background = {
          ...quizData.background,
          image: design.uploadImage.backgroundFilename,
        };
      }

      try {
        await linkUserToGame(gameId as string, token as string);
        await updateQuizLink(gameId as string, quizData, token as string);
        toast.success("Link updated successfully!");
        setTimeout(() => {
          sessionStorage.clear();
          window.location.href = `${REACT_APP_WORKPLAY_URL}/dashboard/campaigns/create?ccid=${ccid}&gameId=${gameId}`;
          handleResetConfirm();
        }, 1500);
      } catch (error) {
        console.error("Error updating quiz link:", error);
        toast.error("Failed to update quiz link");
      }
    } else {
      console.error("Missing necessary parameters for updating quiz link.");
      toast.error("Missing necessary parameters for updating quiz link.");
    }
  };

  return (
    <div>
      <section className="flex items-center justify-between bg-primary px-6 py-5 text-[15px] text-secondary lg:py-4">
        <img
          src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
          alt="workplay-logo"
          className="h-5 cursor-pointer lg:h-6"
          onClick={() => window.open(`${REACT_APP_WORKPLAY_URL}`, "_blank")}
        />
        <div className="hidden items-center space-x-3 font-semibold lg:flex">
          <div className="z-50">
            <TooltipComponent
              id="preview"
              content="Preview"
              place="bottom"
              offset={20}
            >
              <div
                className=" flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-secondary transition-colors duration-300 hover:bg-gray-300"
                onClick={() => navigate("/preview")}
              >
                <div className="hover:scale-105">
                  <Eye className="text-black" size={18} />
                </div>
              </div>{" "}
            </TooltipComponent>
          </div>
          {(!gameId || !ccid || !token) && (
            <div className="relative">
              <button
                className="rounded-full bg-[#f0f0f1] px-6 py-2.5 text-primary transition-colors duration-300 hover:bg-[#e2e2e2]"
                onClick={() => setShowResetDropdown((prev) => !prev)}
              >
                Reset
              </button>
              {showResetDropdown && (
                <div className="absolute -right-20 top-16 z-50 w-72 rounded-xl border border-gray-200 bg-white px-5 py-4 text-black shadow-sm drop-shadow">
                  <div className="absolute -top-2 right-28 h-0 w-0 border-b-8 border-l-8 border-r-8 border-b-white border-l-transparent border-r-transparent"></div>
                  <div className="absolute -top-1 right-28 h-0 w-0 border-b-8 border-l-8 border-r-8 border-b-white border-l-transparent border-r-transparent"></div>
                  <p className="text-left text-base">Confirm Reset</p>
                  <p className="mt-2 text-left text-[15px] font-medium">
                    Are you sure you want to reset? You will lose all changes
                    made.
                  </p>
                  <div className="mt-4 flex w-full items-center justify-end space-x-3">
                    <button
                      className="rounded-full border border-stone-200 bg-stone-100 px-6 py-2 text-sm text-black shadow-sm hover:opacity-80"
                      onClick={() => setShowResetDropdown(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="w-fit rounded-full bg-black px-4 py-2 text-sm text-white hover:opacity-80"
                      onClick={handleResetConfirm}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}

          <button
            className="rounded-full bg-tertiary px-6 py-2.5 transition-colors duration-300 hover:bg-blue-600"
            onClick={
              gameId && ccid && token ? handleUpdateLink : handleGenerateLink
            }
          >
            {gameId && ccid && token ? "Update Link" : "Generate Link"}
          </button>
        </div>
        {showPopup && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative rounded-xl bg-white px-12 py-10 text-center text-black">
              {" "}
              <X
                className="absolute right-3 top-3 cursor-pointer"
                size={20}
                onClick={() => {
                  setShowPopup(false);
                }}
              />
              <h2 className="my-2 text-lg font-bold">
                Your personalized quiz is ready for you!
              </h2>
              <p className="mb-8 text-base">Click 'Get Link' to access it.</p>
              <button
                className="rounded-full bg-blue-500 px-6 py-2 font-medium text-white hover:bg-blue-600"
                onClick={handleGetLink}
              >
                Get Link
              </button>
            </div>
          </div>
        )}
      </section>
      <div className="flex items-center justify-between border bg-white px-4 py-3 text-[15px] font-semibold text-secondary shadow-sm lg:hidden">
        <p className=" text-black" onClick={() => navigate(-1)}>
          <ArrowLeft />
        </p>
        <div className="flex items-center space-x-2">
          <div className="z-50">
            <TooltipComponent
              id="preview"
              content="Preview"
              place="bottom"
              offset={20}
            >
              <div
                className=" flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-secondary transition-colors duration-300 hover:bg-gray-300"
                onClick={() => navigate("/preview")}
              >
                <div className="hover:scale-105">
                  <Eye className="text-black" size={18} />
                </div>
              </div>{" "}
            </TooltipComponent>
          </div>
          {(!gameId || !ccid || !token) && (
            <div className="relative">
              <button
                className="rounded-full bg-[#f0f0f1] px-5 py-2.5 text-sm text-primary transition-colors duration-300 hover:bg-[#e2e2e2]"
                onClick={() => setShowResetDropdown((prev) => !prev)}
              >
                Reset
              </button>
            </div>
          )}

          <button
            className="rounded-full bg-tertiary px-5 py-2.5 text-sm transition-colors duration-300 hover:bg-blue-600"
            onClick={
              gameId && ccid && token ? handleUpdateLink : handleGenerateLink
            }
          >
            {gameId && ccid && token ? "Update Link" : "Generate Link"}
          </button>
        </div>
      </div>

      {showResetDropdown && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 lg:hidden">
          <div className="relative w-[300px] max-w-md rounded-xl bg-white px-6 py-6">
            <p className="text-left text-base font-semibold">Confirm Reset</p>
            <p className="mb-2 mt-3 text-left text-[15px] font-medium">
              Are you sure you want to reset? You will lose all changes made.
            </p>
            <div className="mt-5 flex items-center justify-end gap-x-3">
              <button
                className="rounded-full border border-stone-200 bg-stone-100 px-6 py-2 text-sm text-black shadow-sm hover:opacity-80"
                onClick={() => !setShowResetDropdown}
              >
                Cancel
              </button>
              <button
                className="rounded-full bg-black px-6 py-2 text-sm text-white hover:opacity-80"
                onClick={handleResetConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
