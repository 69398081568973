import { Check, X } from "react-feather";
import { useMediaQuery } from "react-responsive";
type OptionType = {
  id: number;
  text: string;
};

const QuestionComponent: React.FC<{
  currentQuestionIndex: number;
  specification: any;
  design: any;
  textStyles: any;
  showResult: boolean;
  selectedOptionId: number | null;
  handleOptionClick: (option: OptionType) => void;
}> = ({
  currentQuestionIndex,
  specification,
  design,
  textStyles,
  showResult,
  selectedOptionId,
  handleOptionClick,
}) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 458px)" });

  return (
    <div className="w-full text-center">
      <h2
        className="m-auto break-words text-xl font-semibold lg:w-[550px]"
        style={{
          fontSize: textStyles.questionSize,
          fontFamily: textStyles.fontFamily,
        }}
      >
        {currentQuestionIndex + 1}.{" "}
        {specification.questions[currentQuestionIndex]?.text}
      </h2>
      <div className="mt-4 flex flex-col items-center space-y-3">
        {specification.questions[currentQuestionIndex]?.options.map(
          (option: { id: number; text: string }, index: number) => {
            const isCorrect =
              option.text ===
              specification.questions[currentQuestionIndex].correctAnswer;
            const isSelected = option.id === selectedOptionId;

            return (
              <button
                key={index}
                className={`flex h-11 w-full items-center break-words rounded-full px-4 text-left ${
                  showResult && isSelected
                    ? isCorrect
                      ? "bg-green-500 text-white"
                      : "bg-red-500 text-white"
                    : showResult && isCorrect
                      ? "bg-green-500 text-white"
                      : "bg-yellow-500"
                } ${design.backgroundType === "uploadImage" ? "border" : ""}`}
                style={
                  design.backgroundType === "uploadImage"
                    ? {
                        borderColor: showResult
                          ? isCorrect
                            ? "green"
                            : isSelected
                              ? "red"
                              : design.uploadImage.textColor
                          : design.uploadImage.textColor,
                        backgroundColor: showResult
                          ? isCorrect || isSelected
                            ? ""
                            : design.uploadImage.primaryBackgroundColor
                          : design.uploadImage.primaryBackgroundColor,
                        fontFamily: textStyles.fontFamily,
                        fontSize: isSmallScreen
                          ? textStyles.optionSize
                          : textStyles.optionSize,
                      }
                    : {
                        fontFamily: textStyles.fontFamily,
                        fontSize: isSmallScreen
                          ? textStyles.optionSize
                          : textStyles.optionSize,
                      }
                }
                onClick={() => handleOptionClick(option)}
                disabled={showResult}
              >
                {showResult &&
                  (isSelected || isCorrect) &&
                  (isCorrect ? (
                    <Check className="mr-2 h-6 w-6 rounded-full bg-white p-1 text-green-500" />
                  ) : (
                    isSelected && (
                      <X className="mr-2 h-6 w-6 rounded-full bg-white p-1 text-red-500" />
                    )
                  ))}
                <span className="mr-2 font-bold">
                  {String.fromCharCode(65 + index)}.
                </span>
                {option.text}
              </button>
            );
          },
        )}
      </div>
    </div>
  );
};

export default QuestionComponent;
