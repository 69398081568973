import React from "react";
import { List } from "react-feather";
import TooltipComponent from "./TooltipComponent";

interface InstructionHeaderProps {
  onShowInstructions: () => void;
  showInstructionsButton: boolean;
}

const InstructionHeader: React.FC<InstructionHeaderProps> = ({
  onShowInstructions,
  showInstructionsButton,
}) => {
  return (
    <>
      {showInstructionsButton && (
        <div className="absolute right-4 top-4">
          <TooltipComponent
            id="instructions-tooltip"
            content="Rules"
            place="bottom"
            offset={10}
          >
            <button
              className="rounded-full bg-white bg-opacity-80 p-2 text-gray-800 hover:bg-opacity-60"
              onClick={onShowInstructions}
            >
              <List size={20} />
            </button>
          </TooltipComponent>
        </div>
      )}
    </>
  );
};

export default InstructionHeader;
