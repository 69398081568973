import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import PageNotFoundPage from "./pages/PageNotFoundPage";
import CustomizeQuizPage from "./pages/CustomizeQuizPage";
import PreviewPlayPage from "./pages/PreviewPlayPage";
import PreviewGamePage from "./pages/PreviewGamePage";
import PreviewResultPage from "./pages/PreviewResultPage";
import QuizPlayPage from "./pages/QuizPlayPage";
import QuizGamePage from "./pages/QuizGamePage";
import QuizResultPage from "./pages/QuizResultPage";
import LeaderboardPage from "./pages/LeaderboardPage";
import "./App.css";

function App(): JSX.Element {
  return (
    <div className="App">
      <BrowserRouter>
        <Toaster position="top-center" reverseOrder={false} />
        <Routes>
          <Route path="/" element={<CustomizeQuizPage />} />
          <Route path="/preview" element={<PreviewPlayPage />} />
          <Route path="/preview-game" element={<PreviewGamePage />} />
          <Route path="/preview-result" element={<PreviewResultPage />} />{" "}
          <Route path="/play" element={<QuizPlayPage />} />
          <Route path="/game" element={<QuizGamePage />} />
          <Route path="/result" element={<QuizResultPage />} />
          <Route path="/leaderboard" element={<LeaderboardPage />} />
          <Route path="*" element={<PageNotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
