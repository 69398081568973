import React, { useState, useEffect, useRef } from "react";
import {
  FaPalette,
  FaHandPointer,
  FaPencilAlt,
  FaQuestion,
  FaUser,
} from "react-icons/fa";
import TooltipComponent from "./TooltipComponent";

const { REACT_APP_WORKPLAY_URL, REACT_APP_S3_URL } = process.env;

interface SidebarProps {
  setActiveContent: (content: string | null) => void;
  activeContent: string | null;
}

const Sidebar: React.FC<SidebarProps> = ({
  setActiveContent,
  activeContent,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const questionButtonRef = useRef<HTMLButtonElement>(null);

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      questionButtonRef.current &&
      !questionButtonRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleContactSupport = () => {
    window.open(`${REACT_APP_WORKPLAY_URL}/contact`, "_blank");
    setDropdownOpen(false);
  };

  return (
    <div className="flex h-full flex-col items-center justify-between px-6 py-6">
      <div className="flex h-fit flex-col items-center space-y-6 rounded-full bg-primary p-2 shadow-lg">
        <button
          className={`rounded-full p-4 hover:scale-105 ${
            activeContent === "specification"
              ? "bg-secondary text-primary"
              : "bg-primary text-secondary"
          }`}
          onClick={() => setActiveContent("specification")}
        >
          <TooltipComponent
            id="pencil"
            content="Specification"
            place="right"
            offset={30}
          >
            <FaPencilAlt size={24} />
          </TooltipComponent>
        </button>

        <button
          className={`rounded-full p-4 hover:scale-105 ${
            activeContent === "design"
              ? "bg-secondary text-primary"
              : "bg-primary text-secondary"
          }`}
          onClick={() => setActiveContent("design")}
        >
          <TooltipComponent
            id="palette"
            content="Design"
            place="right"
            offset={30}
          >
            <FaPalette size={24} />
          </TooltipComponent>
        </button>

        <button
          className={`rounded-full p-4 hover:scale-105 ${
            activeContent === "interactivity"
              ? "bg-secondary text-primary"
              : "bg-primary text-secondary"
          }`}
          onClick={() => setActiveContent("interactivity")}
        >
          <TooltipComponent
            id="pointer"
            content="Interactivity"
            place="right"
            offset={30}
          >
            <FaHandPointer size={24} />
          </TooltipComponent>
        </button>
      </div>
      <div className="mt-4 flex h-fit flex-col items-center space-y-6 rounded-full bg-primary p-2 shadow-lg">
        <button className="rounded-full p-4 text-secondary hover:scale-105">
          <TooltipComponent
            id="templates"
            content="Templates - Coming Soon!"
            place="right"
            offset={30}
          >
            <img
              src={`${REACT_APP_S3_URL}/assets/icons/template.webp`}
              alt="template"
              className="h-7"
            />
          </TooltipComponent>
        </button>
      </div>
      <div className="relative mt-auto">
        <button
          ref={questionButtonRef}
          className={`rounded-full bg-primary p-3 hover:scale-105 ${
            dropdownOpen
              ? "animate-pulse ring-4 ring-gray-400 ring-opacity-50"
              : "shadow-xl"
          }`}
          onClick={toggleDropdown}
        >
          <FaQuestion size={16} className="text-secondary" />
        </button>
        {dropdownOpen && (
          <div
            ref={dropdownRef}
            className="absolute bottom-14 right-0 w-48 rounded-xl bg-white shadow"
          >
            <div className="absolute right-3 top-[calc(100%-1px)] h-0 w-0 border-x-8 border-t-8 border-x-transparent border-t-white drop-shadow"></div>
            <ul className="p-2">
              <li
                className="flex cursor-pointer items-center space-x-2 rounded-lg p-2 hover:bg-gray-100"
                onClick={handleContactSupport}
              >
                <FaUser size={16} />
                <span>Contact support</span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
