import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InteractivityState {
  displayLeaderboard: boolean;
  numberOfWinners: number;
}

const initialState: InteractivityState = {
  displayLeaderboard: true,
  numberOfWinners: 5,
};

const interactivitySlice = createSlice({
  name: "interactivity",
  initialState,
  reducers: {
    resetInteractivity: () => initialState,
    setDisplayLeaderboard: (state, action: PayloadAction<boolean>) => {
      state.displayLeaderboard = action.payload;
    },
    setNumberOfWinners: (state, action: PayloadAction<number>) => {
      state.numberOfWinners = action.payload;
    },
  },
});

export const { resetInteractivity, setDisplayLeaderboard, setNumberOfWinners } =
  interactivitySlice.actions;
export default interactivitySlice.reducer;
