import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Option {
  id: number;
  text: string;
}

interface Question {
  id: number;
  text: string;
  options: Option[];
  correctAnswer: string;
}

interface Instruction {
  id: number;
  text: string;
}

interface SpecificationState {
  title: string;
  description: string;
  questions: Question[];
  quizTimer: number;
  displayInstructions: boolean;
  instructions: Instruction[];
  logo: {
    file: File | null;
    previewUrl: string | null;
  };
  logoFilename: string | null;
  websiteUrl: string;
}

const initialState: SpecificationState = {
  title: "Quiz Time",
  description: "Get ready to play! Click 'Play' to start your quiz adventure.",
  questions: [],
  quizTimer: 30,
  displayInstructions: false,
  instructions: [],
  logo: {
    file: null,
    previewUrl: null,
  },
  logoFilename: null,
  websiteUrl: "",
};

const specificationSlice = createSlice({
  name: "specification",
  initialState,
  reducers: {
    resetQuiz: () => initialState,
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    setDescription(state, action: PayloadAction<string>) {
      state.description = action.payload;
    },
    setQuestions(state, action: PayloadAction<Question[]>) {
      state.questions = action.payload;
    },
    addQuestion(state) {
      const newQuestion: Question = {
        id: state.questions.length + 1,
        text: "This is question",
        options: [
          { id: 1, text: "Option 1" },
          { id: 2, text: "Option 2" },
        ],
        correctAnswer: "",
      };
      state.questions.push(newQuestion);
    },
    removeQuestion(state, action: PayloadAction<{ questionId: number }>) {
      state.questions = state.questions
        .filter((question) => question.id !== action.payload.questionId)
        .map((question, index) => ({
          ...question,
          id: index + 1,
        }));
    },
    updateQuestionText(
      state,
      action: PayloadAction<{ questionId: number; text: string }>,
    ) {
      const question = state.questions.find(
        (question) => question.id === action.payload.questionId,
      );
      if (question) {
        question.text = action.payload.text;
      }
    },
    addOption(state, action: PayloadAction<{ questionId: number }>) {
      const question = state.questions.find(
        (question) => question.id === action.payload.questionId,
      );
      if (question && question.options.length < 5) {
        const newOption: Option = {
          id: question.options.length + 1,
          text: `Option ${question.options.length + 1}`,
        };
        question.options.push(newOption);
      }
    },
    removeOption(
      state,
      action: PayloadAction<{ questionId: number; optionId: number }>,
    ) {
      const question = state.questions.find(
        (question) => question.id === action.payload.questionId,
      );
      if (question) {
        question.options = question.options
          .filter((option) => option.id !== action.payload.optionId)
          .map((option, index) => {
            const newId = index + 1;
            const isDefaultText = option.text.startsWith("Option ");
            return {
              ...option,
              id: newId,
              text: isDefaultText ? `Option ${newId}` : option.text,
            };
          });
      }
    },
    updateOption(
      state,
      action: PayloadAction<{
        questionId: number;
        optionId: number;
        text: string;
      }>,
    ) {
      const question = state.questions.find(
        (question) => question.id === action.payload.questionId,
      );
      if (question) {
        const option = question.options.find(
          (option) => option.id === action.payload.optionId,
        );
        if (option) {
          option.text = action.payload.text;
        }
      }
    },
    updateCorrectAnswer(
      state,
      action: PayloadAction<{ questionId: number; correctAnswer: string }>,
    ) {
      const question = state.questions.find(
        (question) => question.id === action.payload.questionId,
      );
      if (question) {
        question.correctAnswer = action.payload.correctAnswer;
      }
    },
    setQuizTimer(state, action: PayloadAction<number>) {
      state.quizTimer = action.payload;
    },
    setDisplayInstructions(state, action: PayloadAction<boolean>) {
      state.displayInstructions = action.payload;
      if (action.payload && state.instructions.length === 0) {
        state.instructions.push({
          id: 1,
          text: "Instruction line 1",
        });
      }
    },
    setInstructions(state, action: PayloadAction<Instruction[]>) {
      state.instructions = action.payload;
    },
    addInstruction(state) {
      if (state.instructions.length < 5) {
        const newInstruction: Instruction = {
          id: state.instructions.length + 1,
          text: `Instruction line ${state.instructions.length + 1}`,
        };
        state.instructions.push(newInstruction);
      }
    },
    updateInstruction(
      state,
      action: PayloadAction<{ id: number; text: string }>,
    ) {
      const instruction = state.instructions.find(
        (instr) => instr.id === action.payload.id,
      );
      if (instruction) {
        instruction.text = action.payload.text;
      }
    },
    removeInstruction(state, action: PayloadAction<number>) {
      state.instructions = state.instructions
        .filter((instr) => instr.id !== action.payload)
        .map((instr, index) => {
          const newId = index + 1;
          const isDefaultText = instr.text.startsWith("Instruction ");
          return {
            ...instr,
            id: newId,
            text: isDefaultText ? `Instruction line ${newId}` : instr.text,
          };
        });
      if (state.instructions.length === 0) {
        state.displayInstructions = false;
      }
    },
    setLogo: (
      state,
      action: PayloadAction<{ file: File | null; previewUrl: string | null }>,
    ) => {
      state.logo.file = action.payload.file;
      state.logo.previewUrl = action.payload.previewUrl;
      state.logoFilename = action.payload.file
        ? action.payload.file.name
        : null;
    },
    setLogoFilename: (state, action: PayloadAction<string | null>) => {
      state.logoFilename = action.payload;
    },
    setWebsiteUrl: (state, action: PayloadAction<string>) => {
      state.websiteUrl = action.payload;
    },
  },
});

export const {
  resetQuiz,
  setTitle,
  setDescription,
  setQuestions,
  addQuestion,
  removeQuestion,
  updateQuestionText,
  addOption,
  removeOption,
  updateOption,
  updateCorrectAnswer,
  setQuizTimer,
  setDisplayInstructions,
  setInstructions,
  addInstruction,
  updateInstruction,
  removeInstruction,
  setLogo,
  setLogoFilename,
  setWebsiteUrl,
} = specificationSlice.actions;

export default specificationSlice.reducer;
