import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

interface TooltipComponentProps {
  id: string;
  content: string;
  place: "top" | "right" | "bottom" | "left";
  offset: number;
  children: React.ReactNode;
}

const TooltipComponent: React.FC<TooltipComponentProps> = ({
  id,
  content,
  place,
  offset,
  children,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [delayTimer, setDelayTimer] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    const showTimer = setTimeout(() => {
      setShowTooltip(true);
    }, 500);
    setDelayTimer(showTimer);
  };

  const handleMouseLeave = () => {
    if (delayTimer) {
      clearTimeout(delayTimer);
      setDelayTimer(null);
    }
    setShowTooltip(false);
  };

  useEffect(() => {
    return () => {
      if (delayTimer) {
        clearTimeout(delayTimer);
      }
    };
  }, [delayTimer]);

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-tooltip-id={id}
        data-tooltip-content={content}
      >
        {children}
      </div>
      <Tooltip
        id={id}
        place={place}
        noArrow
        offset={offset}
        isOpen={showTooltip}
        delayHide={0}
        style={{ borderRadius: "20px" }}
      />
    </>
  );
};

export default TooltipComponent;
