import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useNavigate } from "react-router-dom";
import BuiltWithWorkplay from "../components/BuiltWithWorkplay";
import toast from "react-hot-toast";
import { useTextStyles } from "../hooks/useTextStyles";
import { useBackgroundStyle } from "../hooks/useBackgroundStyles";
import LogoComponent from "../components/LogoComponent";
import { useLogoSize } from "../hooks/useLogoSize";

const PreviewResultPage: React.FC = () => {
  const navigate = useNavigate();
  const design = useSelector((state: RootState) => state.design);
  const interactivity = useSelector((state: RootState) => state.interactivity);
  const specification = useSelector((state: RootState) => state.specification);
  const logoSize = useLogoSize(specification.logo.previewUrl);
  const [score, setScore] = useState<number>(() => {
    const storedScore = sessionStorage.getItem("score");
    return storedScore ? JSON.parse(storedScore) : 0;
  });
  const totalQuestions = specification.questions.length;
  const hasWon = score === totalQuestions;
  const { backgroundStyle, buttonBackgroundColor } = useBackgroundStyle();
  const textStyles = useTextStyles();

  const handleDone = () => {
    navigate("/preview", { replace: true });
    sessionStorage.removeItem("previewName");
    sessionStorage.removeItem("previewEmail");
    sessionStorage.removeItem("currentQuestionIndex");
    sessionStorage.removeItem("score");
    sessionStorage.removeItem("remainingTime");
    sessionStorage.removeItem("totalTimeTaken");
  };

  const handleExitPreview = () => {
    navigate(-1);
    const itemsToRemove = [
      "previewName",
      "previewEmail",
      "currentQuestionIndex",
      "score",
      "remainingTime",
      "totalTimeTaken",
    ];
    itemsToRemove.forEach((item) => sessionStorage.removeItem(item));
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-white"
      style={backgroundStyle}
    >
      <div
        className="flex w-[90%] items-center justify-center rounded-xl px-8 py-10 lg:w-auto lg:px-14"
        style={{
          backgroundColor:
            design.backgroundType === "uploadImage"
              ? `rgba(${parseInt(
                  design.uploadImage.secondaryBackgroundColor.slice(1, 3),
                  16,
                )}, ${parseInt(
                  design.uploadImage.secondaryBackgroundColor.slice(3, 5),
                  16,
                )}, ${parseInt(
                  design.uploadImage.secondaryBackgroundColor.slice(5, 7),
                  16,
                )}, 0.7)`
              : `rgba(${parseInt(
                  design.colorPalette.selectedBackgroundColor.slice(1, 3),
                  16,
                )}, ${parseInt(
                  design.colorPalette.selectedBackgroundColor.slice(3, 5),
                  16,
                )}, ${parseInt(
                  design.colorPalette.selectedBackgroundColor.slice(5, 7),
                  16,
                )}, 0.7)`,
          fontFamily: textStyles.fontFamily,
          outlineColor:
            design.backgroundType === "uploadImage"
              ? design.uploadImage.secondaryBackgroundColor
              : design.colorPalette.selectedBackgroundColor,
        }}
      >
        <div className="w-full">
          <LogoComponent specification={specification} logoSize={logoSize} />
          <h1
            className="mb-4 font-bold uppercase"
            style={{
              fontSize: textStyles.titleSize,
              fontFamily: textStyles.fontFamily,
            }}
          >
            {hasWon ? "Congratulations! You won!" : "game over"}
          </h1>
          <p
            className="mx-auto mb-6 max-w-80"
            style={{
              fontSize: textStyles.descriptionSize,
              fontFamily: textStyles.fontFamily,
            }}
          >
            {score === totalQuestions
              ? `You have answered all questions correctly with a score of ${score}. Well done!`
              : `You have completed the quiz with a score of ${score}. Better luck next time!`}
          </p>
          <div className="flex flex-col space-y-4">
            <button
              className={`mx-auto mt-2 w-[160px] rounded-full p-2.5 font-bold uppercase shadow-md hover:opacity-80 ${
                design.backgroundType === "uploadImage" ? "" : "bg-yellow-500"
              }`}
              style={
                design.backgroundType === "uploadImage"
                  ? {
                      backgroundColor: buttonBackgroundColor,
                      fontSize: textStyles.buttonSize,
                      fontFamily: textStyles.fontFamily,
                    }
                  : {
                      fontSize: textStyles.buttonSize,
                      fontFamily: textStyles.fontFamily,
                    }
              }
              onClick={handleDone}
            >
              ok
            </button>
            {interactivity.displayLeaderboard && (
              <button
                className={`mx-auto mt-8 w-[160px] rounded-full p-2.5 font-bold uppercase shadow-md hover:opacity-80 ${
                  design.backgroundType === "uploadImage" ? "" : "bg-yellow-500"
                }`}
                style={
                  design.backgroundType === "uploadImage"
                    ? {
                        backgroundColor: buttonBackgroundColor,
                        fontSize: textStyles.buttonSize,
                        fontFamily: textStyles.fontFamily,
                      }
                    : {
                        fontSize: textStyles.buttonSize,
                        fontFamily: textStyles.fontFamily,
                      }
                }
                onClick={() =>
                  toast.error("Leaderboard not available in preview.", {
                    style: {
                      fontFamily: textStyles.fontFamily,
                      borderRadius: "30px",
                    },
                  })
                }
              >
                Leaderboard
              </button>
            )}
          </div>
        </div>

        <button
          className="absolute bottom-14 rounded-full bg-black px-4 py-2 text-center text-white hover:scale-105 hover:opacity-80"
          onClick={handleExitPreview}
        >
          Exit Preview
        </button>
        <BuiltWithWorkplay />
      </div>
    </div>
  );
};

export default PreviewResultPage;
