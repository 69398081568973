import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ChevronLeft, ChevronRight } from "react-feather";
import TooltipComponent from "./TooltipComponent";
import BuiltWithWorkplay from "./BuiltWithWorkplay";
import { useLogoSize } from "../hooks/useLogoSize";

interface ResultScreenProps {
  setCurrentScreen: (screen: number) => void;
}

const ResultScreen: React.FC<ResultScreenProps> = ({ setCurrentScreen }) => {
  const design = useSelector((state: RootState) => state.design);
  const interactivity = useSelector((state: RootState) => state.interactivity);
  const specification = useSelector((state: RootState) => state.specification);
  const [score] = React.useState(5);
  const logoSize = useLogoSize(specification.logo.previewUrl, {
    minHeightPercentage: 0.026,
    maxHeightPercentage: 0.12,
    maxWidthPercentage: 0.3,
  });

  const backgroundStyle =
    design.backgroundType === "uploadImage"
      ? {
          color: design.uploadImage.textColor,
          backgroundColor: design.uploadImage.primaryBackgroundColor,
          backgroundImage: design.uploadImage.previewUrl
            ? `url(${design.uploadImage.previewUrl})`
            : "none",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }
      : {
          color: design.colorPalette.textColor,
          backgroundColor: design.colorPalette.selectedBackgroundColor,
        };

  const textStyles = {
    fontFamily: design.fontFamily,
    titleSize: `${design.textSizes.title - 2}px`,
    descriptionSize: `${design.textSizes.description - 2}px`,
    buttonSize: `${design.textSizes.button - 2}px`,
  };

  const buttonBackgroundColor =
    design.backgroundType === "uploadImage"
      ? design.uploadImage.primaryBackgroundColor
      : "bg-yellow-500";

  return (
    <div className="relative h-full w-full lg:bottom-9 lg:h-[88%]">
      <div
        className="relative flex h-[95vh] w-full flex-col items-center justify-center rounded-xl border border-secondary bg-white p-8 lg:h-full"
        style={backgroundStyle}
      >
        <div className="absolute left-4 top-1/2 -translate-y-1/2 transform ">
          <TooltipComponent
            id="next-screen"
            content="Game screen"
            place="bottom"
            offset={10}
          >
            <button
              className="rounded-full bg-white p-2 hover:opacity-80"
              onClick={() => setCurrentScreen(1)}
            >
              <ChevronLeft size={20} />
            </button>
          </TooltipComponent>
        </div>
        <div
          className="flex w-auto items-center justify-center rounded-xl px-12 py-10"
          style={{
            backgroundColor:
              design.backgroundType === "uploadImage"
                ? `rgba(${parseInt(design.uploadImage.secondaryBackgroundColor.slice(1, 3), 16)}, ${parseInt(design.uploadImage.secondaryBackgroundColor.slice(3, 5), 16)}, ${parseInt(design.uploadImage.secondaryBackgroundColor.slice(5, 7), 16)}, 0.7)`
                : `rgba(${parseInt(design.colorPalette.selectedBackgroundColor.slice(1, 3), 16)}, ${parseInt(design.colorPalette.selectedBackgroundColor.slice(3, 5), 16)}, ${parseInt(design.colorPalette.selectedBackgroundColor.slice(5, 7), 16)}, 0.7)`,
            fontFamily: textStyles.fontFamily,
            outlineColor:
              design.backgroundType === "uploadImage"
                ? design.uploadImage.secondaryBackgroundColor
                : design.colorPalette.selectedBackgroundColor,
          }}
        >
          <div className="w-full">
            {specification.logo.previewUrl && (
              <div className="mb-6 flex items-center justify-center">
                {specification.websiteUrl ? (
                  <a
                    href={specification.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={specification.logo.previewUrl}
                      alt="Logo"
                      style={{
                        width: `${logoSize.width}px`,
                        height: `${logoSize.height}px`,
                      }}
                    />
                  </a>
                ) : (
                  <img
                    src={specification.logo.previewUrl}
                    alt="Logo"
                    style={{
                      width: `${logoSize.width}px`,
                      height: `${logoSize.height}px`,
                    }}
                  />
                )}
              </div>
            )}
            <h1
              className="mb-4 text-center font-bold"
              style={{
                fontSize: textStyles.titleSize,
                fontFamily: textStyles.fontFamily,
              }}
            >
              Congratulations! You won!
            </h1>
            <p
              className="mb-4 max-w-80 text-center"
              style={{
                fontSize: textStyles.descriptionSize,
                fontFamily: textStyles.fontFamily,
              }}
            >
              You have answered all questions correctly with a score of {score}.
              Well done!
            </p>
            <div className="flex flex-col space-y-4">
              <button
                className={`mx-auto mt-2 w-[160px] rounded-full p-2.5 font-bold uppercase shadow-md hover:opacity-80 ${
                  design.backgroundType === "uploadImage" ? "" : "bg-yellow-500"
                }`}
                style={
                  design.backgroundType === "uploadImage"
                    ? {
                        backgroundColor: buttonBackgroundColor,
                        fontSize: textStyles.buttonSize,
                        fontFamily: textStyles.fontFamily,
                      }
                    : {
                        fontSize: textStyles.buttonSize,
                        fontFamily: textStyles.fontFamily,
                      }
                }
              >
                done
              </button>
              {interactivity.displayLeaderboard && (
                <button
                  className={`mx-auto mt-8 w-[160px] rounded-full p-2.5 font-bold uppercase shadow-md hover:opacity-80 ${
                    design.backgroundType === "uploadImage"
                      ? ""
                      : "bg-yellow-500"
                  }`}
                  style={
                    design.backgroundType === "uploadImage"
                      ? {
                          backgroundColor: buttonBackgroundColor,
                          fontSize: textStyles.buttonSize,
                          fontFamily: textStyles.fontFamily,
                        }
                      : {
                          fontSize: textStyles.buttonSize,
                          fontFamily: textStyles.fontFamily,
                        }
                  }
                >
                  leaderboard
                </button>
              )}
            </div>
          </div>
        </div>
        <button
          className="absolute right-4 top-1/2 -translate-y-1/2 transform cursor-not-allowed rounded-full bg-white p-2 opacity-50"
          disabled
        >
          <ChevronRight size={20} />
        </button>
        <BuiltWithWorkplay />
      </div>
      <div className="mb-16 mt-2 rounded-md py-3 text-start lg:mb-0 lg:bg-[#f5f5f6] lg:px-4 lg:py-2">
        <p className="text-[15px] font-medium">
          <span className="text-lg font-semibold">Result Screen: </span>A sample
          game result and score are displayed. If the leaderboard display is
          enabled, the button will be shown; otherwise, it will not. Click{" "}
          <strong>"Preview"</strong> to view the game in full screen with all
          functionalities.
        </p>
        <p className="flex items-center text-[15px]"> </p>
      </div>
    </div>
  );
};

export default ResultScreen;
