import React from "react";
import { X } from "react-feather";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useTextStyles } from "../hooks/useTextStyles";

interface InstructionModalProps {
  onClose: () => void;
}

const InstructionModal: React.FC<InstructionModalProps> = ({ onClose }) => {
  const design = useSelector((state: RootState) => state.design);
  const specification = useSelector((state: RootState) => state.specification);
  const textStyles = useTextStyles();
  return (
    <div
      className="relative w-[86%] max-w-md rounded-lg bg-white p-6 lg:w-[50%]"
      style={{
        fontSize: textStyles.descriptionSize,
        fontFamily: textStyles.fontFamily,
        backgroundColor:
          design.backgroundType === "uploadImage"
            ? design.uploadImage.primaryBackgroundColor
            : design.colorPalette.selectedBackgroundColor,
        maxHeight: "80vh",
        overflowY: "auto",
      }}
    >
      <button
        className="absolute right-3 top-3 rounded-full bg-white bg-opacity-80 p-1 text-gray-800 hover:bg-opacity-60"
        onClick={onClose}
      >
        <X size={16} />
      </button>
      <h2 className="my-3 text-center text-xl font-bold">Instructions</h2>
      <ul className="list-disc pl-5 text-left">
        {specification.instructions.map((instruction, index) => (
          <li
            key={index}
            className="mb-2 break-words"
            style={{ fontSize: textStyles.inputSize }}
          >
            {instruction.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InstructionModal;
