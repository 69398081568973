import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const generateQuizLink = async (quizData: any) => {
  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/game/quiz`,
      quizData,
    );
    return response.data;
  } catch (error) {
    console.error("Error generating quiz link:", error);
    throw error;
  }
};
