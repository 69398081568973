import React from "react";
import { Switch } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import {
  setDisplayLeaderboard,
  setNumberOfWinners,
} from "../store/slices/InteractivitySlice";
import CustomNumberSelect from "./CustomNumberSelect";

const Interactivity: React.FC = () => {
  const displayLeaderboard = useSelector(
    (state: RootState) => state.interactivity.displayLeaderboard,
  );
  const numberOfWinners = useSelector(
    (state: RootState) => state.interactivity.numberOfWinners,
  );
  const dispatch = useDispatch();

  return (
    <div className="py-6">
      <div className="flex items-center justify-between">
        <label className="block text-base font-semibold">
          Display Leaderboard
        </label>
        <Switch
          checked={displayLeaderboard}
          onChange={() => dispatch(setDisplayLeaderboard(!displayLeaderboard))}
          className={`${displayLeaderboard ? "bg-blue-500" : "bg-gray-300"}
              relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Display Leaderboard</span>
          <span
            className={`${
              displayLeaderboard ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
      <p className="mt-2 w-60 text-sm text-gray-500">
        We will collect user details like name and email.
      </p>
      {displayLeaderboard && (
        <div className="mt-6 flex items-center justify-between">
          <label className="block text-base font-semibold">
            Number of Top Winners to Display
          </label>
          <CustomNumberSelect
            value={numberOfWinners}
            onChange={(value) => dispatch(setNumberOfWinners(value))}
          />
        </div>
      )}
    </div>
  );
};

export default Interactivity;
