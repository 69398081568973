import { useEffect, useState } from "react";
import { getQuizDetails } from "../api/getQuizDetailsAPI";
import { toast } from "react-hot-toast";

const useFetchQuizDetails = (campaignId: string | null) => {
  const [quizDetails, setQuizDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (campaignId) {
      const fetchQuizDetails = async () => {
        try {
          const response = await getQuizDetails(campaignId);
          if (response.data) {
            setQuizDetails(response.data);

            if (response.data.background?.image) {
              const filename = response.data.background.image;
              const imageUrl = `${process.env.REACT_APP_S3_URL}/quiz/background/${filename}`;
              const res = await fetch(imageUrl);
              const blob = await res.blob();
              const file = new File([blob], filename, { type: blob.type });
              const previewUrl = URL.createObjectURL(file);
              setQuizDetails((prevDetails: any) => ({
                ...prevDetails,
                background: {
                  ...prevDetails.background,
                  previewUrl,
                },
              }));
            }
          } else {
            toast.error("Failed to load quiz details.");
          }
        } catch (error) {
          console.error("Error fetching quiz details:", error);
          toast.error("Failed to load quiz details.");
        } finally {
          setLoading(false);
        }
      };

      fetchQuizDetails();
    }
  }, [campaignId]);

  return { quizDetails, loading };
};

export default useFetchQuizDetails;
