import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const postUserSession = async (data: {
  quizId: string;
  fullName: string;
  email: string;
  score: number;
  duration: number;
}) => {
  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/game/quiz/user-session`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error("Error posting user session:", error);
    throw error;
  }
};
