import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Clock, Check, X, ChevronLeft, ChevronRight } from "react-feather";
import TooltipComponent from "./TooltipComponent";
import BuiltWithWorkplay from "./BuiltWithWorkplay";
import { useLogoSize } from "../hooks/useLogoSize";

type OptionType = {
  id: number;
  text: string;
};

interface GameScreenProps {
  setCurrentScreen: (screen: number) => void;
}

const GameScreen: React.FC<GameScreenProps> = ({ setCurrentScreen }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(1);
  const [showResult, setShowResult] = useState(true);
  const [selectedOptionId, setSelectedOptionId] = useState<number | null>(null);
  const quizTimer = useSelector(
    (state: RootState) => state.specification.quizTimer,
  );
  const [remainingTime, setRemainingTime] = useState(quizTimer);
  const specification = useSelector((state: RootState) => state.specification);
  const design = useSelector((state: RootState) => state.design);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const logoSize = useLogoSize(specification.logo.previewUrl, {
    minHeightPercentage: 0.026,
    maxHeightPercentage: 0.12,
    maxWidthPercentage: 0.3,
  });

  // Prevent the timer from starting initially
  useEffect(() => {
    setRemainingTime(quizTimer);
    return () => clearInterval(timerRef.current!);
  }, [quizTimer]);

  const backgroundStyle =
    design.backgroundType === "uploadImage"
      ? {
          color: design.uploadImage.textColor,
          backgroundColor: design.uploadImage.primaryBackgroundColor,
          backgroundImage: design.uploadImage.previewUrl
            ? `url(${design.uploadImage.previewUrl})`
            : "none",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }
      : {
          color: design.colorPalette.textColor,
          backgroundColor: design.colorPalette.selectedBackgroundColor,
        };

  const textStyles = {
    fontFamily: design.fontFamily,
    scoreSize: `${design.textSizes.score - 2}px`,
    timerSize: `${design.textSizes.timer - 2}px`,
    questionSize: `${design.textSizes.question - 2}px`,
    optionSize: `${design.textSizes.option - 2}px`,
  };

  const buttonBackgroundColor =
    design.backgroundType === "uploadImage"
      ? design.uploadImage.primaryBackgroundColor
      : "bg-yellow-500";

  useEffect(() => {
    if (specification.questions.length > 0) {
      const correctOption = specification.questions[
        currentQuestionIndex
      ].options.find(
        (option) =>
          option.text ===
          specification.questions[currentQuestionIndex].correctAnswer,
      );
      setSelectedOptionId(correctOption ? correctOption.id : null);
    }
  }, [specification.questions, currentQuestionIndex]);

  return (
    <div className="relative h-full w-full lg:bottom-9 lg:h-[88%]">
      <div
        className="relative flex h-[95vh] w-full flex-col items-center justify-center rounded-xl border border-secondary bg-white p-8 lg:h-full"
        style={backgroundStyle}
      >
        <div className="absolute left-4 top-1/2 -translate-y-1/2 transform ">
          <TooltipComponent
            id="next-screen"
            content="Play screen"
            place="bottom"
            offset={10}
          >
            <button
              className="rounded-full bg-white p-2 hover:opacity-80"
              onClick={() => setCurrentScreen(0)}
            >
              <ChevronLeft size={20} />
            </button>
          </TooltipComponent>
        </div>{" "}
        <div
          className="flex w-auto items-center justify-center rounded-xl px-7 py-7"
          style={{
            backgroundColor:
              design.backgroundType === "uploadImage"
                ? `rgba(${parseInt(design.uploadImage.secondaryBackgroundColor.slice(1, 3), 16)}, ${parseInt(design.uploadImage.secondaryBackgroundColor.slice(3, 5), 16)}, ${parseInt(design.uploadImage.secondaryBackgroundColor.slice(5, 7), 16)}, 0.7)`
                : `rgba(${parseInt(design.colorPalette.selectedBackgroundColor.slice(1, 3), 16)}, ${parseInt(design.colorPalette.selectedBackgroundColor.slice(3, 5), 16)}, ${parseInt(design.colorPalette.selectedBackgroundColor.slice(5, 7), 16)}, 0.7)`,
            fontFamily: textStyles.fontFamily,
            outlineColor:
              design.backgroundType === "uploadImage"
                ? design.uploadImage.secondaryBackgroundColor
                : design.colorPalette.selectedBackgroundColor,
          }}
        >
          <div className="w-full">
            {specification.logo.previewUrl && (
              <div className="mb-4 flex items-center justify-center">
                {specification.websiteUrl ? (
                  <a
                    href={specification.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={specification.logo.previewUrl}
                      alt="Logo"
                      style={{
                        width: `${logoSize.width}px`,
                        height: `${logoSize.height}px`,
                      }}
                    />
                  </a>
                ) : (
                  <img
                    src={specification.logo.previewUrl}
                    alt="Logo"
                    style={{
                      width: `${logoSize.width}px`,
                      height: `${logoSize.height}px`,
                    }}
                  />
                )}
              </div>
            )}
            <div className="flex w-full justify-center space-x-4">
              <div
                className="flex items-center space-x-2 rounded-full px-4 py-2"
                style={
                  design.backgroundType === "uploadImage"
                    ? {
                        backgroundColor: `rgba(${parseInt(design.uploadImage.textColor.slice(1, 3), 16)}, ${parseInt(design.uploadImage.textColor.slice(3, 5), 16)}, ${parseInt(design.uploadImage.textColor.slice(5, 7), 16)}, 0.8)`,
                        color: design.uploadImage.secondaryBackgroundColor,
                        fontSize: textStyles.scoreSize,
                        fontFamily: textStyles.fontFamily,
                      }
                    : {
                        backgroundColor: "black",
                        color: "white",
                        fontSize: textStyles.scoreSize,
                        fontFamily: textStyles.fontFamily,
                      }
                }
              >
                <span>Score: {score}</span>
              </div>
              <div
                className="flex items-center space-x-2 rounded-full px-4 py-2"
                style={
                  design.backgroundType === "uploadImage"
                    ? {
                        backgroundColor: `rgba(${parseInt(design.uploadImage.textColor.slice(1, 3), 16)}, ${parseInt(design.uploadImage.textColor.slice(3, 5), 16)}, ${parseInt(design.uploadImage.textColor.slice(5, 7), 16)}, 0.8)`,
                        color: design.uploadImage.secondaryBackgroundColor,
                        fontSize: textStyles.scoreSize,
                        fontFamily: textStyles.fontFamily,
                      }
                    : {
                        backgroundColor: "black",
                        color: "white",
                        fontSize: textStyles.scoreSize,
                        fontFamily: textStyles.fontFamily,
                      }
                }
              >
                <Clock className="h-4 w-4" />
                <span>
                  00:{remainingTime < 10 ? `0${remainingTime}` : remainingTime}
                </span>
              </div>
            </div>
            <div className="mb-2 mt-4 flex w-full justify-center">
              <div className="flex w-full">
                {specification.questions.map((_, index) => (
                  <div
                    key={index}
                    className={`mx-1 h-[3px] flex-1 rounded-full bg-white ${
                      index < currentQuestionIndex ||
                      currentQuestionIndex ===
                        specification.questions.length - 1
                        ? ""
                        : "opacity-20"
                    }`}
                  ></div>
                ))}
              </div>
            </div>
            <div className="w-full text-center">
              <h2
                className="m-auto break-words text-xl font-semibold"
                style={{
                  fontSize: textStyles.questionSize,
                  fontFamily: textStyles.fontFamily,
                }}
              >
                {currentQuestionIndex + 1}.{" "}
                {specification.questions[currentQuestionIndex]?.text}
              </h2>
              <div className="mt-4 flex flex-col items-center space-y-3">
                {specification.questions[currentQuestionIndex]?.options.map(
                  (option: { id: number; text: string }, index: number) => {
                    const isCorrect =
                      option.text ===
                      specification.questions[currentQuestionIndex]
                        .correctAnswer;
                    const isSelected = option.id === selectedOptionId;

                    return (
                      <button
                        key={index}
                        className={`flex h-11 w-full items-center break-words rounded-full px-4 text-left  ${
                          showResult && isSelected
                            ? isCorrect
                              ? "bg-green-500 text-white"
                              : "bg-red-500 text-white"
                            : showResult && isCorrect
                              ? "bg-green-500 text-white"
                              : "bg-yellow-500"
                        } ${design.backgroundType === "uploadImage" ? "border" : ""}`}
                        style={
                          design.backgroundType === "uploadImage"
                            ? {
                                borderColor: showResult
                                  ? isCorrect
                                    ? "green"
                                    : isSelected
                                      ? "red"
                                      : design.uploadImage.textColor
                                  : design.uploadImage.textColor,
                                backgroundColor: showResult
                                  ? isCorrect || isSelected
                                    ? ""
                                    : design.uploadImage.primaryBackgroundColor
                                  : design.uploadImage.primaryBackgroundColor,
                                fontFamily: textStyles.fontFamily,
                                fontSize: textStyles.optionSize,
                              }
                            : {
                                fontFamily: textStyles.fontFamily,
                                fontSize: textStyles.optionSize,
                              }
                        }
                        disabled={showResult}
                      >
                        {showResult &&
                          (isSelected || isCorrect) &&
                          (isCorrect ? (
                            <Check className="mr-2 h-6 w-6 rounded-full bg-white p-1 text-green-500" />
                          ) : (
                            isSelected && (
                              <X className="mr-2 h-6 w-6 rounded-full bg-white p-1 text-red-500" />
                            )
                          ))}
                        <span className="mr-2 font-bold">
                          {String.fromCharCode(65 + index)}.
                        </span>
                        {option.text}
                      </button>
                    );
                  },
                )}
              </div>
            </div>
          </div>
        </div>
        <BuiltWithWorkplay />
        <div className="absolute right-4 top-1/2 -translate-y-1/2 transform ">
          <TooltipComponent
            id="next-screen"
            content="Result screen"
            place="bottom"
            offset={10}
          >
            <button
              className="rounded-full bg-white p-2 hover:opacity-80"
              onClick={() => setCurrentScreen(2)}
            >
              <ChevronRight size={20} />
            </button>
          </TooltipComponent>
        </div>
      </div>
      <div className="mb-16 mt-2 rounded-md py-3 text-start lg:mb-0 lg:bg-[#f5f5f6] lg:px-4 lg:py-2">
        <p className="text-[15px] font-medium">
          <span className="text-lg font-semibold">Game Screen: </span>
          View a sample question and its options, track score and timer. Click{" "}
          <strong>"Preview"</strong> to view the game in full screen with all
          functionalities.
        </p>
        <p className="flex items-center text-[15px]"> </p>
      </div>{" "}
    </div>
  );
};

export default GameScreen;
