import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import {
  setBackgroundType,
  setSelectedBackgroundColor,
  setUploadImageTextColor,
  setUploadPrimaryBackgroundColor,
  setUploadSecondaryBackgroundColor,
  setUploadImage,
  setTextSize,
  setFontFamily,
} from "../store/slices/designSlice";
import { FaChevronDown } from "react-icons/fa";
import BackgroundImageUpload from "./BackgroundImageUpload";
import { Minus, Plus } from "react-feather";
import FontFamilySelect from "./FontFamilySelect";
import defaultBackground from "../assets/default-background.webp";

const Design: React.FC = () => {
  const dispatch = useDispatch();
  const { backgroundType, colorPalette, uploadImage, textSizes, fontFamily } =
    useSelector((state: RootState) => state.design);

  useEffect(() => {
    if (
      colorPalette.selectedBackgroundColor === "#00b0ff" &&
      colorPalette.backgroundColors.length > 0
    ) {
      dispatch(setSelectedBackgroundColor(colorPalette.backgroundColors[0]));
    }
  }, [
    dispatch,
    colorPalette.backgroundColors,
    colorPalette.selectedBackgroundColor,
  ]);

  const handleBackgroundColorClick = (color: string) => {
    dispatch(setSelectedBackgroundColor(color));
  };

  useEffect(() => {
    if (backgroundType === "uploadImage" && !uploadImage.previewUrl) {
      dispatch(
        setUploadImage({
          file: null,
          previewUrl: defaultBackground,
        }),
      );
    }
  }, [backgroundType, uploadImage.previewUrl, dispatch]);

  const handleImageUpload = (file: File | null) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result as string;
        dispatch(setUploadImage({ file, previewUrl: result }));
      };
      reader.readAsDataURL(file);
    } else {
      dispatch(setUploadImage({ file: null, previewUrl: defaultBackground }));
    }
  };

  return (
    <div className="mt-3">
      <div className="mb-4">
        <label className="block text-base font-semibold">
          Choose your background
        </label>
        <div className="mb-2 mt-4 block">
          <label className="mr-6 flex cursor-pointer items-center">
            <input
              type="radio"
              name="backgroundType"
              value="colorPalette"
              checked={backgroundType === "colorPalette"}
              onChange={() => dispatch(setBackgroundType("colorPalette"))}
              className="mr-2 scale-125 transform cursor-pointer"
            />
            Color palette
          </label>
        </div>
        <div className="mb-2 mt-4 block">
          <label className="flex cursor-pointer items-center">
            <input
              type="radio"
              name="backgroundType"
              value="uploadImage"
              checked={backgroundType === "uploadImage"}
              onChange={() => dispatch(setBackgroundType("uploadImage"))}
              className="mr-2 scale-125 transform cursor-pointer"
            />
            Upload background image
          </label>
        </div>
      </div>
      {backgroundType === "colorPalette" && (
        <>
          <hr className="my-8" />
          <div>
            <div className="mb-4">
              <h3 className="text-base font-semibold">Text</h3>
              <div className="mt-2 flex items-center justify-between">
                <label className="block text-base font-medium">
                  Text color
                </label>
                <div className="relative flex items-center rounded-md border border-[#dadde1] p-1">
                  <div
                    className="h-6 w-6 cursor-not-allowed rounded-md"
                    style={{
                      backgroundColor: colorPalette.textColor,
                      cursor: "not-allowed",
                    }}
                    onClick={(e) => e.preventDefault()}
                  />
                  <FaChevronDown className="ml-2 h-3 text-gray-400" />
                </div>
              </div>
              <div className="mt-4 flex items-center justify-between">
                <label className="block text-base font-medium">Text Size</label>
                <div className="flex items-center rounded-md border border-[#dadde1]">
                  <button
                    onClick={() =>
                      dispatch(
                        setTextSize({
                          title: textSizes.title + 1,
                          description: textSizes.description + 1,
                          input: textSizes.input + 1,
                          button: textSizes.button + 1,
                          score: textSizes.score + 1,
                          timer: textSizes.timer + 1,
                          question: textSizes.question + 1,
                          option: textSizes.option + 1,
                        }),
                      )
                    }
                    disabled={textSizes.title >= 24}
                    className={`${textSizes.title >= 24 ? "cursor-not-allowed rounded-l-md bg-gray-100" : ""} p-2 hover:opacity-60`}
                  >
                    <Plus className="h-4 w-4" />
                  </button>
                  <div className="h-8 border-l border-[#dadde1]"></div>
                  <button
                    onClick={() =>
                      dispatch(
                        setTextSize({
                          title: textSizes.title - 1,
                          description: textSizes.description - 1,
                          input: textSizes.input - 1,
                          button: textSizes.button - 1,
                          score: textSizes.score - 1,
                          timer: textSizes.timer - 1,
                          question: textSizes.question - 1,
                          option: textSizes.option - 1,
                        }),
                      )
                    }
                    disabled={textSizes.title <= 20}
                    className={`${textSizes.title <= 20 ? "cursor-not-allowed rounded-r-md bg-gray-100" : ""} p-2 hover:opacity-60`}
                  >
                    <Minus className="h-4 w-4" />
                  </button>
                </div>
              </div>
              <div className="mt-4 flex items-center justify-between">
                <label className="block text-base font-medium">Font</label>
                <FontFamilySelect
                  value={fontFamily}
                  onChange={(value) => dispatch(setFontFamily(value))}
                />
              </div>
            </div>
            <div className="my-5 border-b border-gray-200"></div>
            <div>
              <h3 className="pt-4 text-base font-semibold">Background</h3>
              <div className="flex items-center justify-between">
                <label className="my-4 block font-medium">
                  Background Color
                </label>
                <div className="mt-2 flex items-center space-x-2">
                  {colorPalette.backgroundColors.map((color, index) => (
                    <div
                      key={index}
                      className={`h-6 w-6 cursor-pointer rounded-md shadow-inner ${
                        color === colorPalette.selectedBackgroundColor
                          ? "h-7 w-7 border-2 border-blue-600"
                          : ""
                      }`}
                      style={{ backgroundColor: color }}
                      onClick={() => handleBackgroundColorClick(color)}
                    />
                  ))}
                  <div
                    className="flex h-6 w-6 cursor-not-allowed items-center justify-center rounded-md border border-[#dadde1]"
                    style={{ cursor: "not-allowed" }}
                  >
                    <Plus className="h-4 w-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {backgroundType === "uploadImage" && (
        <>
          <hr className="my-8" />
          <BackgroundImageUpload
            textColor={uploadImage.textColor}
            primaryBackgroundColor={uploadImage.primaryBackgroundColor}
            secondaryBackgroundColor={uploadImage.secondaryBackgroundColor}
            onTextColorChange={(color) =>
              dispatch(setUploadImageTextColor(color))
            }
            onPrimaryBackgroundColorChange={(color) =>
              dispatch(setUploadPrimaryBackgroundColor(color))
            }
            onSecondaryBackgroundColorChange={(color) =>
              dispatch(setUploadSecondaryBackgroundColor(color))
            }
            onImageUpload={handleImageUpload}
          />
        </>
      )}
    </div>
  );
};

export default Design;
