import "../styles/LogoLoader.css";

interface LoaderProps {}

const LogoLoader: React.FC<LoaderProps> = ({}) => {
  return (
    <div className="loading-container relative  flex   flex-col items-center justify-center bg-white">
      <div className="spinner z-80 "></div>
    </div>
  );
};

export default LogoLoader;
