import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useMediaQuery } from "react-responsive";

export const useTextStyles = () => {
  const design = useSelector((state: RootState) => state.design);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

  const textStyles = {
    fontFamily: design.fontFamily,
    titleSize: isSmallScreen
      ? `${design.textSizes.title * 0.92}px`
      : `${design.textSizes.title}px`,
    descriptionSize: isSmallScreen
      ? `${design.textSizes.description * 0.92}px`
      : `${design.textSizes.description}px`,
    inputSize: isSmallScreen
      ? `${design.textSizes.input * 0.925}px`
      : `${design.textSizes.input}px`,
    buttonSize: isSmallScreen
      ? `${design.textSizes.button * 0.95}px`
      : `${design.textSizes.button}px`,
    scoreSize: isSmallScreen
      ? `${design.textSizes.score * 0.95}px`
      : `${design.textSizes.score}px`,
    timerSize: isSmallScreen
      ? `${design.textSizes.timer * 0.95}px`
      : `${design.textSizes.timer}px`,
    questionSize: isSmallScreen
      ? `${design.textSizes.question * 0.9}px`
      : `${design.textSizes.question}px`,
    optionSize: isSmallScreen
      ? `${design.textSizes.option * 0.86}px`
      : `${design.textSizes.option}px`,
  };

  return textStyles;
};
