import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import {
  addOption,
  addQuestion,
  setQuestions,
  updateOption,
  removeOption,
  updateQuestionText,
  updateCorrectAnswer,
  removeQuestion,
  setTitle,
  setDescription,
  setQuizTimer,
  setDisplayInstructions,
  addInstruction,
  updateInstruction,
  removeInstruction,
  setLogo,
  setWebsiteUrl,
  setLogoFilename,
} from "../store/slices/specificationSlice";
import { X, Plus, Trash } from "react-feather";
import CustomSelect from "./CustomSelect";
import { Switch } from "@headlessui/react";
import defaultQuestions from "../data/defaultQuestions.json";
import { useDropzone } from "react-dropzone";
import { uploadLogoImage } from "../api/uploadLogoImageAPI";

const { REACT_APP_S3_URL } = process.env;

const quizTimerOptions = [
  { label: "30 seconds" },
  { label: "45 seconds" },
  { label: "60 seconds" },
];

const Specification: React.FC = () => {
  const [focusedQuestion, setFocusedQuestion] = useState<number | null>(null);
  const [focusedOption, setFocusedOption] = useState<{
    questionId: number;
    optionId: number;
  } | null>(null);
  const questions = useSelector(
    (state: RootState) => state.specification.questions,
  );
  const quizTimer = useSelector(
    (state: RootState) => state.specification.quizTimer,
  );
  const specification = useSelector((state: RootState) => state.specification);
  const logoFilename = useSelector(
    (state: RootState) => state.specification.logoFilename,
  );
  const instructions = useSelector(
    (state: RootState) => state.specification.instructions,
  );
  const logo = useSelector((state: RootState) => state.specification.logo);
  const websiteUrl = useSelector(
    (state: RootState) => state.specification.websiteUrl,
  );

  const displayInstructions = useSelector(
    (state: RootState) => state.specification.displayInstructions,
  );
  const [focusedInstruction, setFocusedInstruction] = useState<number | null>(
    null,
  );

  const dispatch = useDispatch();

  const [localTitle, setLocalTitle] = useState(specification.title);
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const [localDescription, setLocalDescription] = useState(
    specification.description,
  );
  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false);

  useEffect(() => {
    setLocalTitle(specification.title);
  }, [specification.title]);

  useEffect(() => {
    setLocalDescription(specification.description);
  }, [specification.description]);

  const handleTitleBlur = () => {
    if (!localTitle.trim()) {
      setLocalTitle(specification.title || "Quiz Time");
      dispatch(setTitle("Quiz Time"));
    }
    setIsTitleFocused(false);
  };

  const handleDescriptionBlur = () => {
    if (!localDescription.trim()) {
      setLocalDescription(
        specification.description ||
          "Get ready to play! Click 'Play' to start your quiz adventure.",
      );
      dispatch(
        setDescription(
          "Get ready to play! Click 'Play' to start your quiz adventure.",
        ),
      );
    }
    setIsDescriptionFocused(false);
  };

  const [localQuestions, setLocalQuestions] = useState(specification.questions);

  useEffect(() => {
    setLocalQuestions(specification.questions);
  }, [specification.questions]);

  const handleQuestionChange = (id: number, newText: string) => {
    setLocalQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.id === id ? { ...question, text: newText } : question,
      ),
    );
    dispatch(updateQuestionText({ questionId: id, text: newText }));
  };

  const handleQuestionBlur = (id: number, index: number) => {
    const question = localQuestions.find((q) => q.id === id);
    if (question && !question.text.trim()) {
      const defaultText = `This is question`;
      setLocalQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === id ? { ...q, text: defaultText } : q,
        ),
      );
      dispatch(updateQuestionText({ questionId: id, text: defaultText }));
    }
    setFocusedQuestion(null);
  };

  const handleOptionChange = (
    questionId: number,
    optionId: number,
    newText: string,
  ) => {
    setLocalQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.id === questionId
          ? {
              ...question,
              options: question.options.map((option) =>
                option.id === optionId ? { ...option, text: newText } : option,
              ),
            }
          : question,
      ),
    );
    dispatch(updateOption({ questionId, optionId, text: newText }));
  };

  const handleOptionBlur = (
    questionId: number,
    optionId: number,
    index: number,
  ) => {
    const question = localQuestions.find((q) => q.id === questionId);
    const option = question?.options.find((opt) => opt.id === optionId);
    if (option && !option.text.trim()) {
      const defaultText = `Option ${index + 1}`;
      setLocalQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === questionId
            ? {
                ...q,
                options: q.options.map((opt) =>
                  opt.id === optionId ? { ...opt, text: defaultText } : opt,
                ),
              }
            : q,
        ),
      );
      dispatch(updateOption({ questionId, optionId, text: defaultText }));
    }
    setFocusedOption(null);
  };

  useEffect(() => {
    if (questions.length === 0) {
      const transformedQuestions = defaultQuestions.questions.map(
        (q, index) => ({
          id: index + 1,
          text: q.question,
          correctAnswer: q.answer,
          options: q.options.map((opt, optIndex) => ({
            id: optIndex,
            text: opt.option,
          })),
        }),
      );
      dispatch(setQuestions(transformedQuestions));
    }
  }, [questions, dispatch]);

  useEffect(() => {
    if (quizTimer === 0) {
      dispatch(setQuizTimer(30));
    }
  }, [quizTimer, dispatch]);

  const handleDisplayInstructionsToggle = () => {
    dispatch(setDisplayInstructions(!displayInstructions));
  };

  const handleQuizTypeChange = (value: string) => {
    console.log("Selected quiz type:", value);
  };

  const handleQuizTimerChange = (value: string) => {
    const timer = parseInt(value.split(" ")[0]);
    dispatch(setQuizTimer(timer));
  };

  const handleCorrectAnswerChange = (questionId: number, value: string) => {
    dispatch(
      updateCorrectAnswer({
        questionId,
        correctAnswer: value,
      }),
    );
  };

  const handleAddInstruction = () => {
    if (instructions.length < 3) {
      dispatch(addInstruction());
    }
  };

  const [localInstructions, setLocalInstructions] = useState(
    specification.instructions,
  );

  useEffect(() => {
    setLocalInstructions(specification.instructions);
  }, [specification.instructions]);

  const handleInstructionBlur = (id: number, index: number) => {
    const instruction = localInstructions.find((instr) => instr.id === id);
    if (instruction && !instruction.text.trim()) {
      const defaultText = `Instruction line ${index + 1}`;
      setLocalInstructions((prevInstructions) =>
        prevInstructions.map((instr) =>
          instr.id === id ? { ...instr, text: defaultText } : instr,
        ),
      );
      dispatch(updateInstruction({ id, text: defaultText }));
    }
    setFocusedInstruction(null);
  };

  const handleInstructionChange = (id: number, newText: string) => {
    setLocalInstructions((prevInstructions) =>
      prevInstructions.map((instr) =>
        instr.id === id ? { ...instr, text: newText } : instr,
      ),
    );
    dispatch(updateInstruction({ id, text: newText }));
  };

  const handleRemoveInstruction = (id: number) => {
    dispatch(removeInstruction(id));
  };

  const onDropLogo = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
          const result = e.target?.result as string;
          dispatch(setLogo({ file, previewUrl: result }));

          try {
            const response = await uploadLogoImage(file);
            if (response && response.data.data && response.data.data.fileName) {
              const filename = response.data.data.fileName;
              dispatch(setLogoFilename(filename));
            }
          } catch (error) {
            console.error("Error uploading logo image:", error);
          }
        };
        reader.readAsDataURL(file);
      }
    },
    [dispatch],
  );

  const handleDeleteLogo = () => {
    dispatch(setLogo({ file: null, previewUrl: null }));
    dispatch(setLogoFilename(null));
    dispatch(setWebsiteUrl(""));
  };

  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } =
    useDropzone({
      onDrop: onDropLogo,
      accept: { "image/*": [] },
      multiple: false,
    });

  return (
    <div>
      <div className="mt-4">
        <label className="mb-3 block text-start text-base font-semibold">
          Quiz type
        </label>
        <div className="relative mt-1">
          <CustomSelect
            value="Q&A"
            options={[
              {
                label: "Q&A",
                icon: `${REACT_APP_S3_URL}/assets/icons/chat.webp`,
              },
            ]}
            onChange={handleQuizTypeChange}
            placeholder="Select Quiz Type"
          />
        </div>
      </div>
      <div className="my-7 border-b border-gray-200"></div>
      <div className="relative mt-6">
        <label className=" block text-start text-base font-semibold">
          Logo
        </label>
        <p className="text-sm text-gray-500">
          We support png, gif, jpg, and svg
        </p>
        <div>
          {logo.previewUrl || logoFilename ? (
            <div className="mb-8 mt-6 flex items-center justify-between space-x-4 rounded-md border border-[#dadde1] bg-[#f5f4f7] p-4 text-center shadow-sm outline-none hover:border-blue-500 hover:ring-1 focus:border-transparent focus:outline-none focus:ring-blue-500">
              <div className="h-20 w-20 flex-shrink-0">
                <img
                  src={
                    logo.previewUrl ||
                    `${REACT_APP_S3_URL}/quiz/logo/${logoFilename}`
                  }
                  alt="Uploaded"
                  className="h-full w-full rounded-md border border-[#dadde1] bg-white object-contain"
                />
              </div>
              <button
                className="flex items-center rounded-md text-base font-medium text-primary underline"
                {...getLogoRootProps()}
              >
                Update logo
              </button>
              <button
                className="flex items-center text-primary"
                onClick={handleDeleteLogo}
              >
                <Trash className="mr-2 h-5" />
              </button>
              <input {...getLogoInputProps()} />
            </div>
          ) : (
            <div
              {...getLogoRootProps()}
              className="mb-8 mt-6 flex cursor-pointer items-center justify-start rounded-md border border-[#dadde1] p-6 text-center hover:border-blue-500 hover:ring-1 "
            >
              <input {...getLogoInputProps()} />
              <div className="flex items-center justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  preserveAspectRatio="xMidYMid meet"
                  width="64"
                  height="64"
                  viewBox="0 0 48 48"
                  className="ml-10"
                >
                  <g color="inherit">
                    <g style={{ opacity: "var(--dropzone-icon-opacity, .5)" }}>
                      <circle
                        cx="27.84"
                        cy="15.36"
                        r="3.84"
                        style={{
                          transition: "all 0.3s ease 0s",
                          transform:
                            "var(--dropzone-icon-primary-transform, none)",
                          fill: "var(--dropzone-icon-primary, #161617)",
                        }}
                      ></circle>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.3553 27.7139L19.92 19.44L8.16 34.56L21.12 34.56L31.68 34.56L39.84 34.56L30.48 22.32L26.3553 27.7139Z"
                        style={{
                          transition: "all 0.3s ease 0s",
                          fill: "var(--dropzone-icon-secondary, rgba(0, 0, 0, 0.55))",
                        }}
                      ></path>
                    </g>
                  </g>
                </svg>
                <p className="w-40 text-primary">
                  Drag and drop or{" "}
                  <span className="cursor-pointer text-black underline">
                    Click to add logo
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {(logo.previewUrl || logoFilename) && (
        <div className="relative mt-6">
          <label className="mb-3 block text-start text-base font-semibold">
            Website URL
          </label>
          <input
            type="text"
            value={websiteUrl}
            onChange={(e) => dispatch(setWebsiteUrl(e.target.value))}
            placeholder="Enter website URL"
            className="mt-3 block h-10 w-full rounded-md border-2 border-transparent bg-[#f5f5f6] px-3 py-2 pr-8 text-[15px] outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      )}
      <div className="my-7 border-b border-gray-200"></div>
      <div className="relative mt-4">
        <label className="mb-3 block text-start text-base font-semibold">
          Title
        </label>
        <input
          type="text"
          value={localTitle}
          onChange={(e) => {
            const newValue = e.target.value;
            setLocalTitle(newValue);
            dispatch(setTitle(newValue));
          }}
          onBlur={handleTitleBlur}
          onFocus={() => setIsTitleFocused(true)}
          placeholder="Enter quiz title"
          className="mt-3 block h-10 w-full rounded-md border-2 border-transparent bg-[#f5f5f6] px-3 py-2 pr-8 text-[15px] outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <span
          className={`absolute bottom-3 right-3 text-xs font-medium text-primary ${isTitleFocused ? "" : "hidden"}`}
          style={{ zIndex: 10 }}
        >
          {60 - localTitle.length}
        </span>
      </div>
      <div className="relative mt-6">
        <label className="mb-3 block text-start text-base font-semibold">
          Description
        </label>
        <textarea
          value={localDescription}
          onChange={(e) => {
            const newValue = e.target.value;
            setLocalDescription(newValue);
            dispatch(setDescription(newValue));
          }}
          onBlur={handleDescriptionBlur}
          onFocus={() => setIsDescriptionFocused(true)}
          placeholder="Enter quiz description"
          className="mt-3 block h-16 w-full rounded-md border-2 border-transparent bg-[#f5f5f6] px-3 py-2 pr-8 text-[15px] outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows={3}
        />
        <span
          className={`absolute bottom-3 right-4 text-xs font-medium text-primary ${isDescriptionFocused ? "" : "hidden"}`}
          style={{ zIndex: 10 }}
        >
          {100 - localDescription.length}
        </span>
      </div>
      <div className="relative mt-6">
        <label className="mb-3 block text-start text-base font-semibold">
          Timer (for each question)
        </label>
        <CustomSelect
          value={`${quizTimer} seconds`}
          options={quizTimerOptions}
          onChange={handleQuizTimerChange}
          placeholder="Select timer"
        />
      </div>
      <div className="relative mt-6 lg:mt-8">
        <div className="flex items-center justify-between">
          <label className="block w-[250px] text-base font-semibold lg:w-full">
            Instructions/Rules for game play
          </label>
          <Switch
            checked={displayInstructions}
            onChange={handleDisplayInstructionsToggle}
            className={`${displayInstructions ? "bg-blue-500" : "bg-gray-300"}
    relative inline-flex h-6 w-12 items-center rounded-full`}
          >
            <span className="sr-only">Display Rules/Instructions</span>
            <span
              className={`${
                displayInstructions ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
        {displayInstructions && (
          <div className="mt-4 space-y-4">
            {localInstructions.map((instruction, index) => (
              <div
                key={instruction.id}
                className="relative flex items-center space-x-2"
              >
                <input
                  type="text"
                  value={instruction.text}
                  onChange={(e) =>
                    handleInstructionChange(instruction.id, e.target.value)
                  }
                  onBlur={() => handleInstructionBlur(instruction.id, index)}
                  onFocus={() => setFocusedInstruction(instruction.id)}
                  placeholder={`Instruction line ${index + 1}`}
                  className="block w-full rounded-md border-2 border-transparent bg-[#f5f5f6] px-3 py-2 pr-8 text-[15px] outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <span
                  className={`absolute bottom-3 right-16 text-xs font-medium text-primary ${
                    focusedInstruction === instruction.id ? "" : "hidden"
                  }`}
                  style={{ zIndex: 10 }}
                >
                  {80 - instruction.text.length}
                </span>
                <button
                  onClick={() => handleRemoveInstruction(instruction.id)}
                  className="flex h-10 w-12 items-center justify-center rounded-lg bg-[#f5f5f6] text-[#adaeaf] hover:bg-[#edecf1]"
                >
                  <X className="h-5" />
                </button>
              </div>
            ))}
            {localInstructions.length < 3 ? (
              <button
                className="mt-4 flex w-full items-center justify-center rounded-full bg-[#f0f0f1] p-3 text-center text-base font-semibold text-primary hover:bg-[#edecf1]"
                onClick={handleAddInstruction}
              >
                <Plus className="mr-2 h-5" /> Add Instruction
              </button>
            ) : (
              <div className="mt-4 rounded-lg bg-blue-100 p-3 text-sm text-blue-700">
                Max limit of 3 instructions reached
              </div>
            )}
          </div>
        )}
      </div>
      <div className="my-5 border-b border-gray-200 lg:my-7"></div>
      <div className="relative mt-6">
        <label className="mb-4 block text-start text-base font-semibold">
          Questions
        </label>
      </div>
      {localQuestions.map((question, questionIndex) => (
        <div
          key={question.id}
          className="mt-4 rounded-md border border-secondary p-4 lg:p-6"
        >
          <div className="flex items-center justify-between">
            <h3 className="text-base font-semibold">Question {question.id}</h3>
            {(localQuestions.length > 1 || question.id !== 1) && (
              <button
                className="text-primary"
                onClick={() =>
                  dispatch(removeQuestion({ questionId: question.id }))
                }
              >
                <X className="h-5" />
              </button>
            )}
          </div>
          <div className="relative mt-4">
            <input
              type="text"
              value={question.text}
              onChange={(e) =>
                handleQuestionChange(question.id, e.target.value)
              }
              onBlur={() => handleQuestionBlur(question.id, questionIndex)}
              onFocus={() => setFocusedQuestion(question.id)}
              placeholder="This is question"
              className="mt-3 block h-10 w-full rounded-md border-2 border-transparent bg-[#f5f5f6] px-3 pr-9 text-[15px] outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <span
              className={`absolute bottom-3 right-3 text-xs font-medium text-primary ${focusedQuestion === question.id ? "" : "hidden"}`}
              style={{ zIndex: 10 }}
            >
              {150 - question.text.length}
            </span>
          </div>
          <h4 className="text-md mt-4 font-semibold">Options</h4>
          {question.options.map((option, optionIndex) => (
            <div
              key={option.id}
              className="relative my-3 flex items-center space-x-2"
            >
              <input
                type="text"
                value={option.text}
                onChange={(e) =>
                  handleOptionChange(question.id, option.id, e.target.value)
                }
                onBlur={() =>
                  handleOptionBlur(question.id, option.id, optionIndex)
                }
                onFocus={() =>
                  setFocusedOption({
                    questionId: question.id,
                    optionId: option.id,
                  })
                }
                placeholder={`Option ${optionIndex + 1}`}
                className="block h-10 w-full rounded-md border-2 border-transparent bg-[#f5f5f6] px-3 pr-9 text-[15px] outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <span
                className={`absolute bottom-3 right-16 text-xs font-medium text-primary ${focusedOption?.questionId === question.id && focusedOption?.optionId === option.id ? "" : "hidden"}`}
                style={{ zIndex: 10 }}
              >
                {80 - option.text.length}
              </span>
              <button
                onClick={() =>
                  dispatch(
                    removeOption({
                      questionId: question.id,
                      optionId: option.id,
                    }),
                  )
                }
                className="flex h-10 w-12 items-center justify-center rounded-lg bg-[#f5f5f6] text-[#adaeaf] hover:bg-[#edecf1]"
              >
                <X className="h-5" />
              </button>
            </div>
          ))}
          {question.options.length < 4 ? (
            <button
              className="mt-4 flex w-full items-center justify-center rounded-full bg-[#f0f0f1] p-3 text-center text-base font-semibold text-primary hover:bg-[#edecf1]"
              onClick={() => dispatch(addOption({ questionId: question.id }))}
            >
              <Plus className="mr-2 h-5" /> Add option
            </button>
          ) : (
            <div className="mt-4 rounded-lg bg-blue-100 p-3 text-sm text-blue-700">
              Max limit of 4 options reached
            </div>
          )}
          <h3 className="mb-3 mt-4 text-base font-semibold">
            Choose correct answer
          </h3>
          {question.options.length > 0 && (
            <CustomSelect
              value={question.correctAnswer || "Select answer"}
              options={question.options.map((option) => ({
                label: option.text,
              }))}
              onChange={(value) =>
                handleCorrectAnswerChange(question.id, value)
              }
              placeholder="Select answer"
            />
          )}
        </div>
      ))}
      {localQuestions.length < 10 ? (
        <button
          className="mb-8 mt-4 flex w-full items-center justify-center rounded-full bg-[#f0f0f1] p-3 text-center text-base font-semibold text-primary hover:bg-[#edecf1]"
          onClick={() => dispatch(addQuestion())}
        >
          <Plus className="mr-2 h-5" /> Add another question
        </button>
      ) : (
        <div className="mb-8 mt-4 rounded-lg bg-blue-100 p-3 text-sm text-blue-700">
          Max limit of 10 questions reached
        </div>
      )}
    </div>
  );
};

export default Specification;
