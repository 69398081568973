const LogoComponent: React.FC<{ specification: any; logoSize: any }> = ({
  specification,
  logoSize,
}) => (
  <div className=" mb-6 flex items-center justify-center">
    {specification.websiteUrl ? (
      <a
        href={specification.websiteUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={specification.logo.previewUrl}
          alt="Logo"
          style={{
            width: `${logoSize.width}px`,
            height: `${logoSize.height}px`,
          }}
        />
      </a>
    ) : (
      <img
        src={specification.logo.previewUrl}
        alt="Logo"
        style={{
          width: `${logoSize.width}px`,
          height: `${logoSize.height}px`,
        }}
      />
    )}
  </div>
);

export default LogoComponent;
