import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ChevronLeft, ChevronRight } from "react-feather";
import TooltipComponent from "./TooltipComponent";
import InstructionModal from "./InstructionModal";
import InstructionHeader from "./InstructionHeader";
import BuiltWithWorkplay from "./BuiltWithWorkplay";
import { useLogoSize } from "../hooks/useLogoSize";

interface PlayScreenProps {
  onStartGame: () => void;
  setCurrentScreen: (screen: number) => void;
}

const PlayScreen: React.FC<PlayScreenProps> = ({
  onStartGame,
  setCurrentScreen,
}) => {
  const [showInstructions, setShowInstructions] = useState(false);
  const design = useSelector((state: RootState) => state.design);
  const interactivity = useSelector((state: RootState) => state.interactivity);
  const specification = useSelector((state: RootState) => state.specification);
  const logoSize = useLogoSize(specification.logo.previewUrl, {
    minHeightPercentage: 0.026,
    maxHeightPercentage: 0.12,
    maxWidthPercentage: 0.3,
  });

  // const backgroundStyle =
  // design.backgroundType === "uploadImage"
  //   ? {
  //       color: design.uploadImage.textColor,
  //       backgroundColor:
  //         design.uploadImage.primaryBackgroundColor ||
  //         design.uploadImage.secondaryBackgroundColor,
  //       backgroundImage: design.uploadImage.previewUrl
  //         ? `url(${design.uploadImage.previewUrl})`
  //         : "none",
  //       backgroundSize: "cover",
  //       backgroundRepeat: "no-repeat",
  //       backgroundPosition: "center",
  //     }
  //   : {
  //       color: design.colorPalette.textColor,
  //       backgroundColor: design.colorPalette.selectedBackgroundColor,
  //     };

  const backgroundStyle =
    design.backgroundType === "uploadImage" && design.uploadImage.previewUrl
      ? {
          color: design.uploadImage.textColor,
          backgroundImage: `url(${design.uploadImage.previewUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }
      : {
          color: design.colorPalette.textColor,
          backgroundColor: design.colorPalette.selectedBackgroundColor,
        };

  const buttonBackgroundColor =
    design.backgroundType === "uploadImage"
      ? design.uploadImage.primaryBackgroundColor
      : "bg-yellow-500";

  const textStyles = {
    fontFamily: design.fontFamily,
    titleSize: `${design.textSizes.title - 2}px`,
    descriptionSize: `${design.textSizes.description - 2}px`,
    inputSize: `${design.textSizes.input - 2}px`,
    buttonSize: `${design.textSizes.button - 2}px`,
  };

  const logoUrl =
    specification.logo.previewUrl ||
    (specification.logoFilename &&
      `${process.env.REACT_APP_S3_URL}/quiz/logo/${specification.logoFilename}`);

  return (
    <div className="relative h-full w-full lg:bottom-9 lg:h-[88%]">
      <div
        className="relative flex h-[95vh] w-full flex-col items-center justify-center rounded-xl border border-secondary bg-white p-8 lg:h-full"
        style={backgroundStyle}
      >
        <button
          className="absolute left-4 top-1/2 -translate-y-1/2 transform cursor-not-allowed rounded-full bg-white p-2 opacity-50"
          disabled
        >
          <ChevronLeft size={20} />
        </button>
        <InstructionHeader
          onShowInstructions={() => setShowInstructions(true)}
          showInstructionsButton={
            specification.displayInstructions &&
            specification.instructions.length > 0
          }
        />
        {showInstructions && (
          <div className="absolute inset-0 z-40 flex items-center justify-center rounded-md bg-black bg-opacity-40">
            <InstructionModal onClose={() => setShowInstructions(false)} />
          </div>
        )}

        <div
          className="flex items-center justify-center  rounded-xl p-7 lg:min-w-[400px]"
          style={{
            backgroundColor:
              design.backgroundType === "uploadImage"
                ? `rgba(${parseInt(design.uploadImage.secondaryBackgroundColor.slice(1, 3), 16)}, ${parseInt(design.uploadImage.secondaryBackgroundColor.slice(3, 5), 16)}, ${parseInt(design.uploadImage.secondaryBackgroundColor.slice(5, 7), 16)}, 0.7)`
                : `rgba(${parseInt(design.colorPalette.selectedBackgroundColor.slice(1, 3), 16)}, ${parseInt(design.colorPalette.selectedBackgroundColor.slice(3, 5), 16)}, ${parseInt(design.colorPalette.selectedBackgroundColor.slice(5, 7), 16)}, 0.7)`,
            fontSize: textStyles.inputSize,
            fontFamily: textStyles.fontFamily,
            outlineColor:
              design.backgroundType === "uploadImage"
                ? design.uploadImage.secondaryBackgroundColor
                : design.colorPalette.selectedBackgroundColor,
          }}
        >
          <div>
            {logoUrl && (
              <div className="mb-4 flex items-center justify-center">
                {specification.websiteUrl ? (
                  <a
                    href={specification.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={logoUrl}
                      alt="Logo"
                      style={{
                        width: `${logoSize.width}px`,
                        height: `${logoSize.height}px`,
                      }}
                    />
                  </a>
                ) : (
                  <img
                    src={logoUrl}
                    alt="Logo"
                    style={{
                      width: `${logoSize.width}px`,
                      height: `${logoSize.height}px`,
                    }}
                  />
                )}
              </div>
            )}
            <h1
              className="mb-4 text-center font-extrabold"
              style={{
                fontSize: textStyles.titleSize,
                fontFamily: textStyles.fontFamily,
              }}
            >
              {specification.title}
            </h1>
            <p
              className="mx-auto max-w-96 text-center"
              style={{
                fontSize: textStyles.descriptionSize,
                fontFamily: textStyles.fontFamily,
              }}
            >
              {specification.description}
            </p>
            {interactivity.displayLeaderboard && (
              <div className="mx-auto mt-4 w-[75%] space-y-4">
                <input
                  type="text"
                  placeholder="Enter your name"
                  className="w-full rounded-full border px-5 py-2.5"
                  style={{
                    fontSize: textStyles.inputSize,
                    fontFamily: textStyles.fontFamily,
                    outlineColor:
                      design.backgroundType === "uploadImage"
                        ? design.uploadImage.primaryBackgroundColor
                        : design.colorPalette.selectedBackgroundColor,
                  }}
                />
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full rounded-full border px-5 py-2.5"
                  style={{
                    fontSize: textStyles.inputSize,
                    fontFamily: textStyles.fontFamily,
                    outlineColor:
                      design.backgroundType === "uploadImage"
                        ? design.uploadImage.primaryBackgroundColor
                        : design.colorPalette.selectedBackgroundColor,
                  }}
                />
              </div>
            )}
            <button
              className={`mx-auto mt-6 rounded-full px-10 py-2.5 font-bold uppercase shadow-md hover:opacity-80 ${
                design.backgroundType === "uploadImage" ? "" : "bg-yellow-500"
              }`}
              style={
                design.backgroundType === "uploadImage"
                  ? {
                      backgroundColor: buttonBackgroundColor,
                      fontSize: textStyles.buttonSize,
                      fontFamily: textStyles.fontFamily,
                    }
                  : {
                      fontSize: textStyles.buttonSize,
                      fontFamily: textStyles.fontFamily,
                    }
              }
            >
              play
            </button>
          </div>
        </div>
        <BuiltWithWorkplay />

        <div className="absolute right-4 top-1/2 -translate-y-1/2 transform ">
          <TooltipComponent
            id="next-screen"
            content="Game screen"
            place="bottom"
            offset={10}
          >
            <button
              className="rounded-full bg-white p-2 hover:opacity-80"
              onClick={() => setCurrentScreen(1)}
            >
              <ChevronRight size={20} />
            </button>
          </TooltipComponent>
        </div>
      </div>{" "}
      <div className="mb-16 mt-2 rounded-md py-3 text-start lg:mb-0 lg:bg-[#f5f5f6] lg:px-4 lg:py-2">
        <p className="text-[15px] font-medium">
          <span className="text-lg font-semibold">Play Screen: </span>
          This is a view of the play page with the quiz title, description,
          optional instructions, name and email fields (if leaderboard is
          enabled), and a "PLAY" button. Click on <strong>"Preview"</strong> to
          experience the game.
        </p>
        <p className="flex items-center text-[15px]"> </p>
      </div>
    </div>
  );
};

export default PlayScreen;
