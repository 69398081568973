import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

interface LoadingSpinnerProps {
  backgroundColor: string;
  secondaryBackgroundColor: string;
  isImageBackground: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  backgroundColor,
  secondaryBackgroundColor,
  isImageBackground,
}) => {
  const loaderColor = isImageBackground ? secondaryBackgroundColor : "#ffffff";

  return (
    <div
      className="fixed inset-0 flex items-center justify-center"
      style={{ backgroundColor }}
    >
      <ClipLoader size={50} color={loaderColor} loading={true} />
    </div>
  );
};

export default LoadingSpinner;
