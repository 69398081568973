import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const uploadBackgroundImage = async (file: File): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.put(
      `${REACT_APP_API_URL}/game/quiz/upload-background-image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading background image:", error);
    throw error;
  }
};
