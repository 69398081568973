import { useSelector } from "react-redux";
import { RootState } from "../store";

export const useBackgroundStyle = () => {
  const design = useSelector((state: RootState) => state.design);

  const backgroundStyle =
    design.backgroundType === "uploadImage"
      ? {
          color: design.uploadImage.textColor,
          backgroundColor:
            design.uploadImage.primaryBackgroundColor ||
            design.uploadImage.secondaryBackgroundColor,
          backgroundImage: design.uploadImage.previewUrl
            ? `url(${design.uploadImage.previewUrl})`
            : "none",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }
      : {
          color: design.colorPalette.textColor,
          backgroundColor: design.colorPalette.selectedBackgroundColor,
        };

  const buttonBackgroundColor =
    design.backgroundType === "uploadImage"
      ? design.uploadImage.primaryBackgroundColor
      : "bg-yellow-500";

  return { backgroundStyle, buttonBackgroundColor };
};
