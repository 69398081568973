import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const getQuizDetails = async (id: string) => {
  try {
    const response = await axios.get(`${REACT_APP_API_URL}/game/quiz/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching quiz details:", error);
    throw error;
  }
};
