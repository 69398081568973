import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TextSizes {
  title: number;
  description: number;
  input: number;
  button: number;
  score: number;
  timer: number;
  question: number;
  option: number;
}

interface DesignState {
  backgroundType: "colorPalette" | "uploadImage";
  colorPalette: {
    textColor: string;
    backgroundColors: string[];
    selectedBackgroundColor: string;
    textSize: number;
    fontFamily: string;
    textSizes: TextSizes;
  };
  uploadImage: {
    textColor: string;
    primaryBackgroundColor: string;
    secondaryBackgroundColor: string;
    image: File | null;
    previewUrl: string | null;
    backgroundFilename?: string;
    textSize: number;
    fontFamily: string;
    textSizes: TextSizes;
  };
  fontFamily: string;
  textSizes: TextSizes;
}

const initialState: DesignState = {
  backgroundType: "colorPalette",
  colorPalette: {
    textColor: "#000000",
    backgroundColors: ["#40ABD7", "#956EEE", "#BBF1CA", "#F3DC91"],
    selectedBackgroundColor: "#F3DC91",
    textSize: 16,
    fontFamily: "Trebuchet MS",
    textSizes: {
      title: 22,
      description: 18,
      input: 16,
      button: 15,
      score: 15,
      timer: 15,
      question: 18,
      option: 16,
    },
  },
  uploadImage: {
    textColor: "#000000",
    primaryBackgroundColor: "#fae379",
    secondaryBackgroundColor: "#d5d4fe",
    image: null,
    previewUrl: null,
    backgroundFilename: "",
    textSize: 16,
    fontFamily: "Trebuchet MS",
    textSizes: {
      title: 22,
      description: 18,
      input: 16,
      button: 15,
      score: 15,
      timer: 15,
      question: 18,
      option: 16,
    },
  },
  fontFamily: "Trebuchet MS",
  textSizes: {
    title: 22,
    description: 18,
    input: 16,
    button: 15,
    score: 15,
    timer: 15,
    question: 18,
    option: 16,
  },
};

const designSlice = createSlice({
  name: "design",
  initialState,
  reducers: {
    resetDesign: () => initialState,
    setBackgroundType: (
      state,
      action: PayloadAction<"colorPalette" | "uploadImage">,
    ) => {
      state.backgroundType = action.payload;
    },
    setColorPaletteTextColor: (state, action: PayloadAction<string>) => {
      state.colorPalette.textColor = action.payload;
    },
    setSelectedBackgroundColor: (state, action: PayloadAction<string>) => {
      state.colorPalette.selectedBackgroundColor = action.payload;
    },
    addBackgroundColor: (state, action: PayloadAction<string>) => {
      state.colorPalette.backgroundColors.push(action.payload);
    },
    setUploadImageTextColor: (state, action: PayloadAction<string>) => {
      state.uploadImage.textColor = action.payload;
    },
    setUploadPrimaryBackgroundColor: (state, action: PayloadAction<string>) => {
      state.uploadImage.primaryBackgroundColor = action.payload;
    },
    setUploadSecondaryBackgroundColor: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.uploadImage.secondaryBackgroundColor = action.payload;
    },
    setUploadImage: (
      state,
      action: PayloadAction<{
        file: File | null;
        previewUrl: string | null;
        filename?: string;
      }>,
    ) => {
      state.uploadImage.image = action.payload.file;
      state.uploadImage.previewUrl = action.payload.previewUrl;
      if (action.payload.filename) {
        state.uploadImage.backgroundFilename = action.payload.filename;
      }
    },
    setBackgroundFilename: (state, action: PayloadAction<string>) => {
      state.uploadImage.backgroundFilename = action.payload;
    },
    setTextSize: (state, action: PayloadAction<TextSizes>) => {
      state.textSizes = action.payload;
    },
    setFontFamily: (state, action: PayloadAction<string>) => {
      state.fontFamily = action.payload;
    },
  },
});

export const {
  resetDesign,
  setBackgroundType,
  setColorPaletteTextColor,
  setSelectedBackgroundColor,
  addBackgroundColor,
  setUploadImageTextColor,
  setUploadPrimaryBackgroundColor,
  setUploadSecondaryBackgroundColor,
  setUploadImage,
  setBackgroundFilename,
  setTextSize,
  setFontFamily,
} = designSlice.actions;

export default designSlice.reducer;
