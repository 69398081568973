import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useNavigate } from "react-router-dom";
import InstructionHeader from "../components/InstructionHeader";
import InstructionModal from "../components/InstructionModal";
import BuiltWithWorkplay from "../components/BuiltWithWorkplay";
import { toast, Toaster } from "react-hot-toast";
import LogoComponent from "../components/LogoComponent";
import { useLogoSize } from "../hooks/useLogoSize";
import { useTextStyles } from "../hooks/useTextStyles";
import { useBackgroundStyle } from "../hooks/useBackgroundStyles";

const PreviewPlayPage: React.FC = () => {
  const navigate = useNavigate();
  const [showInstructions, setShowInstructions] = useState(false);
  const [previewName, setPreviewName] = useState(
    sessionStorage.getItem("previewName") || "",
  );
  const [previewEmail, setPreviewEmail] = useState(
    sessionStorage.getItem("previewEmail") || "",
  );
  const design = useSelector((state: RootState) => state.design);
  const interactivity = useSelector((state: RootState) => state.interactivity);
  const specification = useSelector((state: RootState) => state.specification);
  const logoSize = useLogoSize(specification.logo.previewUrl);
  const { backgroundStyle, buttonBackgroundColor } = useBackgroundStyle();
  const textStyles = useTextStyles();

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateName = (name: string) => {
    return /^[a-zA-Z\s]+$/.test(name);
  };

  useEffect(() => {
    const savedName = sessionStorage.getItem("previewName");
    const savedEmail = sessionStorage.getItem("previewEmail");

    if (savedName) {
      setPreviewName(savedName);
    } else {
      const defaultName = "John Doe";
      setPreviewName(defaultName);
      sessionStorage.setItem("previewName", defaultName);
    }

    if (savedEmail) {
      setPreviewEmail(savedEmail);
    } else {
      const defaultEmail = "john.doe@example.com";
      setPreviewEmail(defaultEmail);
      sessionStorage.setItem("previewEmail", defaultEmail);
    }
  }, []);

  const handlePlayClick = () => {
    if (interactivity.displayLeaderboard) {
      if (!previewName || !previewEmail) {
        toast.error("Please fill in all fields.", {
          style: {
            fontFamily: textStyles.fontFamily,
            borderRadius: "30px",
          },
        });
        return;
      }

      if (!validateEmail(previewEmail)) {
        toast.error("Please enter a valid email address.", {
          style: {
            fontFamily: textStyles.fontFamily,
            borderRadius: "30px",
          },
        });
        return;
      }

      if (!validateName(previewName)) {
        toast.error("Name should not contain numbers.", {
          style: {
            fontFamily: textStyles.fontFamily,
            borderRadius: "30px",
          },
        });
        return;
      }
    }

    sessionStorage.setItem("previewName", previewName);
    sessionStorage.setItem("previewEmail", previewEmail);

    navigate("/preview-game", { replace: true });
  };

  const handleExitPreview = () => {
    navigate(-1);
    const itemsToRemove = [
      "previewName",
      "previewEmail",
      "currentQuestionIndex",
      "score",
      "remainingTime",
      "totalTimeTaken",
    ];
    itemsToRemove.forEach((item) => sessionStorage.removeItem(item));
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white">
      <Toaster />
      <div
        className="relative flex h-full w-full flex-col items-center justify-center p-8"
        style={backgroundStyle}
      >
        <InstructionHeader
          onShowInstructions={() => setShowInstructions(true)}
          showInstructionsButton={
            specification.displayInstructions &&
            specification.instructions.length > 0
          }
        />
        {showInstructions && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <InstructionModal onClose={() => setShowInstructions(false)} />
          </div>
        )}

        <div
          className="flex min-w-[310px] items-center justify-center rounded-xl px-8 py-10 lg:min-w-[400px] lg:p-10"
          style={{
            backgroundColor:
              design.backgroundType === "uploadImage"
                ? `rgba(${parseInt(design.uploadImage.secondaryBackgroundColor.slice(1, 3), 16)}, ${parseInt(design.uploadImage.secondaryBackgroundColor.slice(3, 5), 16)}, ${parseInt(design.uploadImage.secondaryBackgroundColor.slice(5, 7), 16)}, 0.7)`
                : `rgba(${parseInt(design.colorPalette.selectedBackgroundColor.slice(1, 3), 16)}, ${parseInt(design.colorPalette.selectedBackgroundColor.slice(3, 5), 16)}, ${parseInt(design.colorPalette.selectedBackgroundColor.slice(5, 7), 16)}, 0.7)`,
            fontSize: textStyles.inputSize,
            fontFamily: textStyles.fontFamily,
            outlineColor:
              design.backgroundType === "uploadImage"
                ? design.uploadImage.secondaryBackgroundColor
                : design.colorPalette.selectedBackgroundColor,
          }}
        >
          <div>
            <div className="mx-auto flex items-center justify-center">
              {specification.logo.previewUrl ? (
                <LogoComponent
                  specification={specification}
                  logoSize={logoSize}
                />
              ) : (
                <div className="flex h-full w-full items-center justify-center">
                  <div className="loader"></div>
                </div>
              )}
            </div>
            <h1
              className="mb-4 text-center font-extrabold uppercase tracking-wider"
              style={{
                fontSize: textStyles.titleSize,
                fontFamily: textStyles.fontFamily,
              }}
            >
              {specification.title}
            </h1>
            <p
              className="mx-auto text-center lg:max-w-96"
              style={{
                fontSize: textStyles.descriptionSize,
                fontFamily: textStyles.fontFamily,
              }}
            >
              {specification.description}
            </p>
            {interactivity.displayLeaderboard && (
              <div className="mx-auto mt-4 space-y-4 lg:w-[75%]">
                <input
                  type="text"
                  placeholder="Enter your name"
                  className="w-full rounded-full border px-5 py-2.5"
                  value={previewName}
                  onChange={(e) => {
                    setPreviewName(e.target.value);
                    sessionStorage.setItem("previewName", e.target.value);
                  }}
                  style={{
                    fontSize: textStyles.inputSize,
                    fontFamily: textStyles.fontFamily,
                    outlineColor:
                      design.backgroundType === "uploadImage"
                        ? design.uploadImage.primaryBackgroundColor
                        : design.colorPalette.selectedBackgroundColor,
                  }}
                />
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full rounded-full border px-5 py-2.5"
                  value={previewEmail}
                  onChange={(e) => {
                    setPreviewEmail(e.target.value);
                    sessionStorage.setItem("previewEmail", e.target.value);
                  }}
                  style={{
                    fontSize: textStyles.inputSize,
                    fontFamily: textStyles.fontFamily,
                    outlineColor:
                      design.backgroundType === "uploadImage"
                        ? design.uploadImage.primaryBackgroundColor
                        : design.colorPalette.selectedBackgroundColor,
                  }}
                />
              </div>
            )}
            <button
              className={`mx-auto mt-8 rounded-full px-10 py-2.5 font-bold uppercase shadow-md hover:opacity-80 ${
                design.backgroundType === "uploadImage" ? "" : "bg-yellow-500"
              }`}
              style={
                design.backgroundType === "uploadImage"
                  ? {
                      backgroundColor: buttonBackgroundColor,
                      fontSize: textStyles.buttonSize,
                      fontFamily: textStyles.fontFamily,
                    }
                  : {
                      fontSize: textStyles.buttonSize,
                      fontFamily: textStyles.fontFamily,
                    }
              }
              onClick={handlePlayClick}
            >
              play
            </button>
          </div>
        </div>
        <BuiltWithWorkplay />
        <button
          className="absolute bottom-14 rounded-full bg-black px-4 py-2 text-center text-white hover:scale-105 hover:opacity-80"
          onClick={handleExitPreview}
        >
          Exit Preview
        </button>
      </div>
    </div>
  );
};

export default PreviewPlayPage;
