import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const updateQuizLink = async (
  campaignId: string,
  quizData: any,
  token: string,
) => {
  try {
    const response = await axios.patch(
      `${REACT_APP_API_URL}/game/quiz/${campaignId}`,
      quizData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error updating quiz link:", error);
    throw error;
  }
};
