import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BuiltWithWorkplay from "../components/BuiltWithWorkplay";
import { toast } from "react-hot-toast";
import { postUserSession } from "../api/postUserSessionAPI";
import useFetchQuizDetails from "../hooks/useFetchQuizDetails";
import LoadingSpinner from "../components/LoadingSpinner";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import LogoComponent from "../components/LogoComponent";
import { useLogoSize } from "../hooks/useLogoSize";
import useGameStyles from "../hooks/useGameStyles";
import LinkActiveOrExpiredPage from "./LinkActiveOrExpiredPage";
import { subDays } from "date-fns";

const QuizResultPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isLinkNotActiveYet, setIsLinkNotActiveYet] = useState(false);
  // const logoSize = useLogoSize(specification.logo.previewUrl);
  const score = sessionStorage.getItem("quizScore");
  const userName = sessionStorage.getItem("quizUserName");
  const userEmail = sessionStorage.getItem("quizUserEmail");
  const totalTimeTaken = sessionStorage.getItem("quizTotalTimeTaken");

  // Ensure that specification.questions is defined and has a length
  const getQueryParams = (query: string) => {
    return query
      ? JSON.parse(
          '{"' +
            decodeURI(query)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}',
        )
      : {};
  };

  const queryParams = getQueryParams(location.search.substring(1));
  const { campaignId } = queryParams;

  const { loading, quizDetails: fetchedQuizDetails } =
    useFetchQuizDetails(campaignId);
  const quizDetails =
    useSelector((state: RootState) => state.game.quizDetails) ||
    fetchedQuizDetails;

  const logoUrl =
    quizDetails && quizDetails.logo
      ? `${process.env.REACT_APP_S3_URL}/quiz/logo/${quizDetails.logo.image}`
      : null;
  const logoSize = useLogoSize(logoUrl);

  const totalQuestions = quizDetails.questions.length;
  const { backgroundStyle, buttonBackgroundColor } = useGameStyles(quizDetails);
  const { textStyles } = useGameStyles(quizDetails);
  // Parse score as a number
  const parsedScore = Number(score);
  const hasWon = parsedScore === totalQuestions;

  useEffect(() => {
    if (quizDetails) {
      const { startDate, endDate } = quizDetails.campaign;
      const adjustedStartDate = subDays(new Date(startDate), 1);
      const adjustedEndDate = subDays(new Date(endDate), 1);
      const currentDate = new Date();
      if (currentDate < adjustedStartDate) {
        setIsLinkNotActiveYet(true);
      } else if (currentDate > adjustedEndDate) {
        setIsLinkExpired(true);
      }
    }
  }, [quizDetails]);

  useEffect(() => {
    if (userName && userEmail) {
      const data = {
        quizId: campaignId,
        fullName: userName,
        email: userEmail,
        score: parsedScore,
        duration: Number(totalTimeTaken),
      };

      postUserSession(data)
        .then((response) => {
          console.log("User session submitted successfully:", response);
        })
        .catch((error) => {
          console.error("Error submitting user session:", error);
          toast.error("Failed to submit user session.");
        });
    }
  }, [userName, userEmail, campaignId, parsedScore, totalTimeTaken]);

  const handleDone = () => {
    navigate(`/play?campaignId=${campaignId}`, { replace: true });
    sessionStorage.removeItem("quizScore");
    sessionStorage.removeItem("quizRemainingTime");
    sessionStorage.removeItem("quizCurrentQuestionIndex");
    sessionStorage.removeItem("quizUserName");
    sessionStorage.removeItem("quizUserEmail");
    sessionStorage.removeItem("quizTotalTimeTaken");
  };

  if (loading || !quizDetails) {
    return (
      <LoadingSpinner
        backgroundColor={quizDetails?.background?.secondaryBColor || "#fff"}
        secondaryBackgroundColor={
          quizDetails?.background?.primaryBColor || "#ffffff"
        }
        isImageBackground={Boolean(quizDetails?.background?.image)}
      />
    );
  }

  if (isLinkNotActiveYet) {
    const { startDate, endDate } = quizDetails.campaign;
    const adjustedStartDate = subDays(new Date(startDate), 1);
    const adjustedEndDate = subDays(new Date(endDate), 1);
    return (
      <LinkActiveOrExpiredPage
        message="This link is not active yet"
        additionalInfo={`You can access this link only between ${new Date(adjustedStartDate).toLocaleString()} and ${new Date(adjustedEndDate).toLocaleString()}.`}
      />
    );
  }

  if (isLinkExpired) {
    return (
      <LinkActiveOrExpiredPage
        message="Oops! Page not Found"
        additionalInfo="This link is no longer active."
      />
    );
  }

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-white"
      style={backgroundStyle}
    >
      <div
        className="flex w-[90%] items-center justify-center rounded-xl px-8 py-10 xsm:w-auto lg:px-14"
        style={{
          backgroundColor: quizDetails.background.image
            ? `rgba(${parseInt(
                quizDetails.background.secondaryBColor.slice(1, 3),
                16,
              )}, ${parseInt(
                quizDetails.background.secondaryBColor.slice(3, 5),
                16,
              )}, ${parseInt(
                quizDetails.background.secondaryBColor.slice(5, 7),
                16,
              )}, 0.7)`
            : `rgba(${parseInt(
                quizDetails.background.backgroundColor.slice(1, 3),
                16,
              )}, ${parseInt(
                quizDetails.background.backgroundColor.slice(3, 5),
                16,
              )}, ${parseInt(
                quizDetails.background.backgroundColor.slice(5, 7),
                16,
              )}, 0.7)`,
          fontFamily: textStyles.fontFamily,
          outlineColor: quizDetails.background.image
            ? quizDetails.background.secondaryBColor
            : quizDetails.background.backgroundColor,
        }}
      >
        <div className="w-full">
          {quizDetails && quizDetails.logo && (
            <LogoComponent
              specification={{
                logo: { previewUrl: logoUrl },
                websiteUrl: quizDetails.logo.websiteUrl,
              }}
              logoSize={logoSize}
            />
          )}
          <h1
            className="mb-4 font-bold uppercase"
            style={{
              fontSize: textStyles.titleSize,
              fontFamily: textStyles.fontFamily,
            }}
          >
            {hasWon ? "Congratulations! You won!" : "Game Over"}
          </h1>
          <p
            className="mx-auto mb-6 max-w-80"
            style={{
              fontSize: textStyles.descriptionSize,
              fontFamily: textStyles.fontFamily,
            }}
          >
            {hasWon
              ? `You have answered all questions correctly with a score of ${score}. Well done!`
              : `You have completed the quiz with a score of ${score}. Better luck next time!`}
          </p>
          <div className="flex flex-col space-y-4">
            <button
              className={`mx-auto mt-2 w-[160px] rounded-full p-2.5 font-bold uppercase shadow-md hover:opacity-80 ${
                quizDetails.background.image ? "" : "bg-yellow-500"
              }`}
              style={{
                backgroundColor: buttonBackgroundColor,
                fontSize: textStyles.buttonSize,
                fontFamily: textStyles.fontFamily,
              }}
              onClick={handleDone}
            >
              ok
            </button>
            {quizDetails.leaderboard && (
              <button
                className={`mx-auto mt-8 w-[160px] rounded-full p-2.5 font-bold uppercase shadow-md hover:opacity-80 ${
                  quizDetails.background.image ? "" : "bg-yellow-500"
                }`}
                style={{
                  backgroundColor: buttonBackgroundColor,
                  fontSize: textStyles.buttonSize,
                  fontFamily: textStyles.fontFamily,
                }}
                onClick={() =>
                  navigate(`/leaderboard?campaignId=${campaignId}`)
                }
              >
                Leaderboard
              </button>
            )}
          </div>
        </div>
        <BuiltWithWorkplay />
      </div>
    </div>
  );
};

export default QuizResultPage;
