import React, { useState } from "react";
import PlayScreen from "./PlayScreen";
import GameScreen from "./GameScreen";
import ResultScreen from "./ResultScreen";

interface MainContentProps {
  className?: string;
}

const MainContent: React.FC<MainContentProps> = ({ className }) => {
  const [currentScreen, setCurrentScreen] = useState(0);

  return (
    <div
      className={`relative flex flex-grow items-center justify-center rounded-xl border-2 border-secondary bg-white p-4 drop-shadow lg:my-6 lg:ml-6 ${className}`}
    >
      {currentScreen === 0 ? (
        <PlayScreen
          onStartGame={() => setCurrentScreen(1)}
          setCurrentScreen={setCurrentScreen}
        />
      ) : currentScreen === 1 ? (
        <GameScreen setCurrentScreen={setCurrentScreen} />
      ) : (
        <ResultScreen setCurrentScreen={setCurrentScreen} />
      )}
    </div>
  );
};

export default MainContent;
