import React, { useState, useEffect, useRef } from "react";
import { Check } from "react-feather";
import { FaChevronDown } from "react-icons/fa";

const numberOptions = [5, 10];

const CustomNumberSelect: React.FC<{
  value: number;
  onChange: (value: number) => void;
}> = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: number) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-20" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex w-full items-center justify-between rounded-md border-2 border-transparent bg-[#f5f4f7] px-4 py-2 text-left text-base font-medium shadow-sm outline-none hover:border-blue-500 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <span>{value}</span>
        <FaChevronDown
          className={`h-3 transition-transform ${isOpen ? "rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-2 w-full rounded-md border border-gray-300 bg-white shadow-lg">
          {numberOptions.map((option) => (
            <div
              key={option}
              onClick={() => handleOptionClick(option)}
              className="flex cursor-pointer items-center justify-between px-4 py-2 hover:bg-gray-100"
            >
              <span>{option}</span>
              {option === value && <Check className="h-4 text-blue-800" />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomNumberSelect;
