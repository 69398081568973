const TimerEndPopup: React.FC<{
  textStyles: any;
  design: any;
  handleTimeUp: () => void;
}> = ({ textStyles, design, handleTimeUp }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div
      className="h-fit w-[85%] rounded-xl bg-white p-6 text-center xsm:w-72"
      style={{
        fontFamily: textStyles.fontFamily,
        backgroundColor:
          design.backgroundType === "uploadImage"
            ? design.uploadImage.secondaryBackgroundColor
            : design.colorPalette.selectedBackgroundColor,
        maxHeight: "80vh",
        overflowY: "auto",
      }}
    >
      <h2 className="mb-2 text-xl font-bold">Time's up!</h2>
      <p>You couldn't complete the quiz in time. Better luck next time!</p>
      <button
        className="mt-5 w-24 rounded-full py-2 uppercase shadow-sm"
        style={{
          fontFamily: textStyles.fontFamily,
          backgroundColor:
            design.backgroundType === "uploadImage"
              ? design.uploadImage.primaryBackgroundColor
              : design.colorPalette.selectedBackgroundColor,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
        onClick={handleTimeUp}
      >
        ok
      </button>
    </div>
  </div>
);

export default TimerEndPopup;
