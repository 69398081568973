import React from "react";
import BuiltWithWorkplay from "../components/BuiltWithWorkplay";

const { REACT_APP_WORKPLAY_URL, REACT_APP_S3_URL } = process.env;
const LinkNotFoundImg = `${REACT_APP_S3_URL}/assets/page-not-found.webp`;

interface LinkActiveOrExpiredPageProps {
  message: string;
  additionalInfo?: string;
}

const LinkActiveOrExpiredPage: React.FC<LinkActiveOrExpiredPageProps> = ({
  message,
  additionalInfo,
}) => {
  return (
    <div className="flex min-h-screen w-full items-center justify-center bg-white p-8">
      <div>
        <img
          className="m-auto h-[220px] sm:h-[400px]"
          src={LinkNotFoundImg}
          alt="work in progress"
        />
        <h1 className="mt-4 text-lg font-medium tracking-wider lg:text-xl">
          {message}
        </h1>
        {additionalInfo && (
          <p className="m-auto mt-5 w-[280px] text-[15px] font-medium text-gray-600 lg:w-full lg:text-[17px]">
            {additionalInfo} <br /> Please{" "}
            <a
              className=" text-blue-800 underline"
              href={`${REACT_APP_WORKPLAY_URL}/contact`}
            >
              contact us
            </a>{" "}
            for assistance.
          </p>
        )}

        <BuiltWithWorkplay />
      </div>
    </div>
  );
};

export default LinkActiveOrExpiredPage;
