import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLeaderboard } from "../api/leaderboardAPI";
import LoadingSpinner from "../components/LoadingSpinner";
import useFetchQuizDetails from "../hooks/useFetchQuizDetails";
import useGameStyles from "../hooks/useGameStyles";
import BuiltWithWorkplay from "../components/BuiltWithWorkplay";
import LogoComponent from "../components/LogoComponent";
import { useLogoSize } from "../hooks/useLogoSize";

type LeaderboardEntry = {
  _id: string;
  fullName: string;
  email: string;
  score: number;
  duration: number;
};

const LeaderboardPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const getQueryParams = (query: string) => {
    return query
      ? JSON.parse(
          '{"' +
            decodeURI(query)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}',
        )
      : {};
  };

  const queryParams = getQueryParams(location.search.substring(1));
  const campaignId = queryParams.campaignId as string;

  const { quizDetails, loading: quizDetailsLoading } =
    useFetchQuizDetails(campaignId);

  const { backgroundStyle, textStyles } = useGameStyles(quizDetails);

  const logoUrl =
    quizDetails && quizDetails.logo
      ? `${process.env.REACT_APP_S3_URL}/quiz/logo/${quizDetails.logo.image}`
      : null;
  const logoSize = useLogoSize(logoUrl);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      setLoading(true); // Set loading state before fetching data

      const cachedData = localStorage.getItem(`leaderboard_${campaignId}`);
      if (cachedData) {
        setLeaderboard(JSON.parse(cachedData));
        setLoading(false);
        return;
      }

      try {
        const data = await getLeaderboard(campaignId);
        let leaderboardData = data.data;

        // Remove duplicate entries based on email
        const emailSet = new Set<string>();
        leaderboardData = leaderboardData.filter((entry: LeaderboardEntry) => {
          if (emailSet.has(entry.email)) {
            return false;
          }
          emailSet.add(entry.email);
          return true;
        });

        setLeaderboard(leaderboardData);
        localStorage.setItem(
          `leaderboard_${campaignId}`,
          JSON.stringify(leaderboardData),
        ); // Cache the data
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch leaderboard data");
        setLoading(false);
      }
    };

    if (quizDetails) {
      fetchLeaderboard();
    }
  }, [campaignId, quizDetails]);

  // Memoize the processed leaderboard data
  const processedLeaderboard = useMemo(() => {
    if (!leaderboard) return [];

    // Sort the leaderboard data based on score and duration
    let sortedLeaderboard = [...leaderboard];
    sortedLeaderboard.sort((a: LeaderboardEntry, b: LeaderboardEntry) => {
      if (b.score !== a.score) {
        return b.score - a.score; // Sort by score in descending order
      }
      return a.duration - b.duration; // Sort by duration in ascending order
    });

    // Get the leaderboard limit from quizDetails
    const limit = quizDetails?.leaderboardLimit || sortedLeaderboard.length;
    return sortedLeaderboard.slice(0, limit); // Limit the entries
  }, [leaderboard, quizDetails]);

  const handleOkClick = () => {
    navigate(`/play?campaignId=${campaignId}`, { replace: true });
    sessionStorage.removeItem("quizScore");
    sessionStorage.removeItem("quizRemainingTime");
    sessionStorage.removeItem("quizCurrentQuestionIndex");
    sessionStorage.removeItem("quizUserName");
    sessionStorage.removeItem("quizUserEmail");
    sessionStorage.removeItem("quizTotalTimeTaken");
    localStorage.removeItem(`leaderboard_${campaignId}`);
  };

  if (loading || quizDetailsLoading) {
    return (
      <LoadingSpinner
        backgroundColor={quizDetails?.background?.secondaryBColor || "#fff"}
        secondaryBackgroundColor={
          quizDetails?.background?.primaryBColor || "#ffffff"
        }
        isImageBackground={Boolean(quizDetails?.background?.image)}
      />
    );
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="fixed inset-0 z-50  py-16" style={backgroundStyle}>
      <div className="w-full">
        {" "}
        {quizDetails && quizDetails.logo && (
          <LogoComponent
            specification={{
              logo: { previewUrl: logoUrl },
              websiteUrl: quizDetails.logo.websiteUrl,
            }}
            logoSize={logoSize}
          />
        )}
        <div
          className="xsm:w-[90%] container mx-auto mt-10 w-[92%] rounded-xl py-8 xl:w-[40%]"
          style={{
            backgroundColor: quizDetails.background.image
              ? `rgba(${parseInt(
                  quizDetails.background.secondaryBColor.slice(1, 3),
                  16,
                )}, ${parseInt(
                  quizDetails.background.secondaryBColor.slice(3, 5),
                  16,
                )}, ${parseInt(
                  quizDetails.background.secondaryBColor.slice(5, 7),
                  16,
                )}, 0.7)`
              : "#facc15",

            outlineColor: quizDetails.background.image
              ? quizDetails.background.secondaryBColor
              : "#facc15",
            fontFamily: quizDetails.fontFamily,
          }}
        >
          <h2 className="mb-6 text-center text-2xl font-bold">Leaderboard</h2>
          <table
            className="xsm:w-[90%] mx-auto w-[80%] overflow-x-auto rounded-xl bg-white bg-opacity-65 font-medium"
            style={{
              border: quizDetails.background.image
                ? undefined
                : "2px solid #facc15",
            }}
          >
            <thead
              style={{
                borderBottom: quizDetails.background.image
                  ? "1px solid #ddd"
                  : "2px solid #facc15",
              }}
            >
              <tr>
                <th className="border-b px-2 py-2 lg:px-6">Rank</th>
                <th className="border-b px-2 py-2 lg:px-6">Name</th>
                <th className="border-b px-2 py-2 lg:px-6">Email</th>
                <th className="border-b px-2 py-2 lg:px-6">Score</th>
                {/* <th className="border-b px-4 py-2">Total Time Taken</th> */}
              </tr>
            </thead>
            <tbody style={{ fontSize: quizDetails.textSize * 0.7 }}>
              {processedLeaderboard.map((entry, index) => (
                <tr key={entry._id}>
                  <td className="border-b px-2 py-2 lg:px-6">{index + 1}</td>
                  <td className="border-b px-2 py-2 lg:px-6">
                    {entry.fullName}
                  </td>
                  <td className="border-b px-2 py-2 lg:px-6">{entry.email}</td>
                  <td className="border-b px-2 py-2 lg:px-6">{entry.score}</td>
                  {/* <td className="border-b px-4 py-2">{entry.duration}</td> */}
                </tr>
              ))}
            </tbody>
          </table>{" "}
        </div>{" "}
        <div className="absolute bottom-14 mt-5 flex w-full items-center justify-center">
          <button
            className="rounded-full px-5 py-2 font-semibold uppercase tracking-wide shadow-sm"
            style={{
              fontFamily: textStyles.fontFamily,
              backgroundColor: quizDetails.background.image
                ? quizDetails.background.primaryBColor
                : "#facc15",
            }}
            onClick={handleOkClick}
          >
            close
          </button>
        </div>
        <BuiltWithWorkplay />
      </div>
    </div>
  );
};

export default LeaderboardPage;
